import { memo } from '../../util/memo';
import { VideoContentCarousel } from './VideoContentCarousel';
import { VideoFrame } from '../cards/video/VideoFrame';
import Stack from '@mui/material/Stack';
import { useSelectedVideoUrl } from '../../contexts/SelectedVideoUrlContext';
import { useTheme } from '@mui/material/styles';
import { VideoContentCarouselMobile } from './VideoContentCarouselMobile';

const MIN_WIDTH_DESKTOP = 1200;

const LivestreamBannerUnmemoized = () => {
  const theme = useTheme();

  const { videos } = useSelectedVideoUrl();

  return (
    <>
      {!!videos?.length && (
        <Stack>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{
              py: '32px',
              display: 'none',
              [`@media (min-width: ${MIN_WIDTH_DESKTOP + 1}px)`]: {
                display: 'flex',
              },
            }}
          >
            <VideoContentCarousel
              vods={videos}
              containerSx={{
                overflowY: 'auto',
                height: '720px',
                maxWidth: '316px',
                ...theme.palette.scrollbars.invisible,
              }}
            />
            <VideoFrame />
          </Stack>

          <Stack
            sx={{
              display: 'none',
              [`@media (max-width: ${MIN_WIDTH_DESKTOP}px)`]: {
                display: 'block',
              },
            }}
          >
            <VideoFrame />
            <VideoContentCarouselMobile
              vods={videos}
              containerSx={{
                overflowX: 'auto',
                py: 2,
                width: '100%',
                ...theme.palette.scrollbars.invisible,
              }}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export const LivestreamBanner = memo(LivestreamBannerUnmemoized);
