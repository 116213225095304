import { memo } from '../../../../util/memo';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { DialogActionsStandard } from '../../../dialog/DialogActionsStandard';
import Stack from '@mui/material/Stack';
import { useTournamentRegistrationForm } from '../../../../hooks/tournaments/useTournamentRegistrationForm';
import { CHECKIN_DIALOG_DIMENSIONS } from '../../../../../functions/src/config/ads/adDimensions';
import { REGISTRATION_AD } from '../../../../../functions/src/util/ads/adIds';
import { AdapexAdContainer } from '../../../ads/AdapexAdContainer';
import { AD_BORDER_RADIUS } from '../../../algolia/catalog-wrappers/GameHorizontalCarousel';

const GameRegisterDialogUnmemoized = () => {
  const { FormFields, submitForm, isFormSubmittable } =
    useTournamentRegistrationForm();

  return (
    <DialogBodyStandard
      title="Registration Details"
      description={
        <Stack spacing={6} alignItems={'center'}>
          <AdapexAdContainer
            baseContainerId={REGISTRATION_AD}
            width={CHECKIN_DIALOG_DIMENSIONS.width}
            height={CHECKIN_DIALOG_DIMENSIONS.height}
            adjustable={CHECKIN_DIALOG_DIMENSIONS.adjustable}
            borderRadius={AD_BORDER_RADIUS}
          />
          {FormFields}
        </Stack>
      }
    >
      <DialogActionsStandard
        buttons={[
          {
            isAsync: false,
            onClick: submitForm,
            sx: { flex: 2 },
            children: 'Confirm',
            disabled: !isFormSubmittable,
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const GameRegisterDialog = memo(GameRegisterDialogUnmemoized);
