import { FC } from 'react';
import { memo } from '../../../util/memo';
import { useSetMatchScreenshot } from '../../../hooks/tournaments/useSetMatchScreenshot';
import AddIcon from '@mui/icons-material/Add';
import { ImageUploadButton } from '../../buttons/ImageUploadButton';
import { useActiveMatchRouter } from '../../../contexts/ActiveMatchRouterContext';
import Tooltip from '@mui/material/Tooltip';

const AddScreenshotUnmemoized: FC = () => {
  const { sessionNumber } = useActiveMatchRouter();
  const { addScreenshot } = useSetMatchScreenshot(sessionNumber);
  return (
    <Tooltip
      title="Please upload a screenshot related to the tournament that you'd like to showcase. Remember, this will be public and viewable by everyone."
      placement="bottom"
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <ImageUploadButton
          sx={{ borderRadius: '50px', mb: 4, width: '100%', height: '40px' }}
          onChange={addScreenshot}
          startIcon={AddIcon}
        >
          SCREENSHOT
        </ImageUploadButton>
      </div>
    </Tooltip>
  );
};

export const AddScreenshot = memo(AddScreenshotUnmemoized);
