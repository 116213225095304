import { useMatch } from '../MatchProvider';
import { MatchCellButton } from './MatchCellIButton';
import { useMatchDetails } from '../details/MatchDetailsProvider';
import { useCallback, useMemo } from 'react';
import { memo } from '../../../../util/memo';
import { MatchDetails } from '../details/MatchDetails';
import { Tournament } from '../../../../../functions/src/types/firestore/Game/Tournament';
import { DialogCentered } from '../../../DialogCentered';
import { DialogBodyStandard } from '../../../dialog/DialogBodyStandard';
import { useActiveMatchRouter } from '../../../../contexts/ActiveMatchRouterContext';

export type CellButtonDetailsProps = {
  tournament: Tournament<Date>;
};

export const CellButtonDetails = memo(function CellButtonDetailsUnmemoized() {
  const { id } = useMatch();
  const { open, close, matchId, sessionNumber } = useActiveMatchRouter();
  const openMatchDetails = useCallback(() => {
    return open(id);
  }, [open, id]);

  const { rounds } = useMatchDetails();
  const MatchDetailsDialog = useMemo(() => {
    const isOpen = sessionNumber !== undefined && matchId === id;
    return (
      <>
        {isOpen && (
          <DialogCentered open={true} onClose={close} showCloseIcon={false}>
            <DialogBodyStandard>
              <MatchDetails rounds={rounds} />
            </DialogBodyStandard>
          </DialogCentered>
        )}
      </>
    );
  }, [close, id, matchId, rounds, sessionNumber]);

  return (
    <>
      <MatchCellButton onClick={openMatchDetails} />
      {MatchDetailsDialog}
    </>
  );
});
