import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { SelectableOption } from '../../SelectableOption';
import { AllSignInMethod } from '../../../../functions/src/types/firestore/User';
import { GradientIcon } from '../../gradients/GradientIcon';
import { useAuthMethodsDisplay } from '../../../hooks/auth/useAuthMethodsDisplay';
import { useSignIn } from '../../../hooks/auth/useSignIn';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import { toTitleCase } from '../../../util/string';

export type ProviderDetail = {
  provider: AllSignInMethod;
  value?: string;
  isSelected?: boolean;
  onSelect?: () => void;
};

export const SelectableRegistrationOption = memo(
  function SelectableRegistrationOptionUnmemoized({
    provider,
    value,
    isSelected,
    onSelect,
  }: ProviderDetail) {
    const authOptionsDisplay = useAuthMethodsDisplay();
    const signIn = useSignIn();
    const { icon } = authOptionsDisplay[`${provider}`];
    const startIcon = useMemo(() => {
      const IconComponent = !!value ? DoneIcon : AddIcon;
      const gradientColor = !!value
        ? 'secondary.horizontal'
        : 'primary.horizontal';

      return (
        <GradientIcon
          IconComponent={IconComponent}
          gradientColor={gradientColor}
        />
      );
    }, [value]);

    const select = useCallback(async () => {
      if (!!provider && !value) {
        await signIn(provider);
        return;
      }
      onSelect?.();
    }, [value, onSelect, provider, signIn]);
    return (
      <SelectableOption
        isSelected={isSelected}
        width={'480px'}
        onClick={select}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          {startIcon}
          <Typography variant="body1" color={'text.primary'}>
            {value || `Connect ${toTitleCase(provider)} Account`}
          </Typography>
        </Stack>
        <GradientIcon IconComponent={icon} gradientColor="primary.horizontal" />
      </SelectableOption>
    );
  },
);
