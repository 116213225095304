import { EliminationPlacementStrategy } from './EliminationPlacementStrategy';
import { Required } from 'utility-types';
import { Bracket, Round } from '../../types/firestore/Game/Tournament/Bracket';
import { isSingleElimination } from '../../types/firestore/Game/Tournament/Bracket/util/isBracket';

export function toPlacementSingleElimination(roundIndex: number): number {
  return 2 ** (roundIndex + 1) + 1;
}

export function toPayoutIndexSingleElimination(placement: number): number {
  return Math.floor(Math.log2(Math.max(placement - 1, 0.5))) + 1;
}

export class SingleEliminationPlacementStrategy<
  TTime,
> extends EliminationPlacementStrategy<TTime> {
  constructor(bracket: Bracket<TTime>) {
    super(bracket);
  }

  // DO NOT ABSTRACT OUT. THIS IS COUPLED TO bracketPlaceable.
  // eslint-disable-next-line class-methods-use-this
  public toPlacement(bracketPlaceableRoundIndex: number): number {
    return 2 ** (bracketPlaceableRoundIndex + 1) + 1;
  }

  // eslint-disable-next-line class-methods-use-this
  public toPayoutIndex(placement: number): number {
    return toPayoutIndexSingleElimination(placement);
  }

  public get finalMatch() {
    return this.finalRound.matches[this.finalRound.matches.length - 1];
  }

  public get finalRound() {
    const { bracket } = this.bracket;
    const finalRound = bracket[bracket.length - 1];
    return finalRound as Required<Round<TTime>, 'matches'>;
  }

  public get bracketPlaceable() {
    const singleElimination = isSingleElimination(this.bracket);
    if (!!singleElimination) {
      const { bracket } = this.bracket;
      if (bracket[0].matches?.length === 1) {
        return [...bracket];
      }
      return [...bracket].reverse().slice(1);
    }
    return [];
  }
}
