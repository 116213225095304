import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { memo } from '../../util/memo';
import { ImageOptimized } from '../image/ImageOptimized';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { LiveBadge } from '../LiveBadge';
import { useSelectedVideoUrl } from '../../contexts/SelectedVideoUrlContext';

//TODO after BLU-4200: This card show show a check if the person streaming is in currently in a game - From @builtByJavi

export type LiveVideoCardProps = {
  thumbnail: string;
  title: string;
  username: string;
  live?: boolean;
  postedAt: string;
  videoUrl: string;
};

const LiveVideoCardUnmemoized = (props: LiveVideoCardProps) => {
  const { thumbnail, title, username, live = true, postedAt, videoUrl } = props;
  const theme = useTheme();

  const { selectedVideoUrl } = useSelectedVideoUrl();

  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsSelected(selectedVideoUrl === videoUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoUrl]);

  return (
    <Stack
      direction="row"
      spacing="8px"
      sx={{
        height: '100px',
        borderRadius: '10px',
        p: '8px',
        background: isSelected
          ? theme.palette.background.elevation[12]
          : theme.palette.background.elevation[2],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '140px',
          height: '81px',
        }}
      >
        <ImageOptimized width="140px" height="81px" src={thumbnail} />
      </Box>
      <Stack
        sx={{ width: '140px', height: '81px', justifyContent: 'space-between' }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            lineHeight: '1',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {username}
        </Typography>
        {live ? (
          <Box sx={{ width: '45px' }}>
            <LiveBadge />
          </Box>
        ) : (
          <Typography variant="subtitle2" color="text.secondary">
            {postedAt}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const LiveVideoCard = memo(LiveVideoCardUnmemoized);
