import { Link } from 'src/components/Link';
import Stack from '@mui/material/Stack';
import { AvatarNext } from 'src/components/AvatarNext';
import { memo } from '../../../util/memo';
import { OptionalWrapper } from 'src/components/OptionalWrapper';
import { Member } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { TeamDisplayTooltip } from './TeamDisplayTooltip';
import { useMobile } from 'src/hooks/useMobile';
import { TypographyTruncated } from 'src/components/TypographyTruncated';

export type UsernameProps = {
  imgUrl: string;
  name: string;
  rank: number;
  userIdCaptain: string;
  members?: Member[];
};

const UsernameUnmemoized = ({
  imgUrl,
  name,
  rank,
  userIdCaptain,
  members = [],
}: UsernameProps) => {
  const typographyVariant = rank > 3 ? 'body2' : 'body1';
  const fontWeight = rank <= 3 ? 700 : 400;
  const isSolo = !!members && members.length === 1;
  const isMobile = useMobile();
  const textColor = 'text.primary';

  return (
    <OptionalWrapper
      doWrap={isSolo}
      Wrapper={
        <Link
          href={`/profile/${userIdCaptain}`}
          style={{ textDecoration: 'none' }}
        />
      }
    >
      <OptionalWrapper
        doWrap={!isSolo}
        Wrapper={
          <TeamDisplayTooltip
            teamDisplayProps={{ avatarUrl: imgUrl, members: members, name }}
          >
            <></>
          </TeamDisplayTooltip>
        }
      >
        <Stack flexDirection="row" justifyContent="center" alignItems="center">
          <AvatarNext src={imgUrl} alt={name} />
          <TypographyTruncated
            text={name}
            color={textColor}
            variant={typographyVariant}
            textMaxLength={isMobile ? 15 : 20}
            sx={{
              ml: '8px',
              fontWeight,
              pointerEvents: isSolo ? undefined : 'none',
            }}
          />
        </Stack>
      </OptionalWrapper>
    </OptionalWrapper>
  );
};
export const Username = memo(UsernameUnmemoized);
