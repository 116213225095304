import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import { isPreReady } from 'functions/src/types/firestore/Game/Tournament/util';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { isUserCheckedIn } from '../../../../functions/src/util/tournaments/isUserCheckedIn';
import { useAuth } from '../../../contexts/AuthContext';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import { CheckInButton } from '../check-in/CheckInButton';
import { TournamentPanelV3 } from '../TournamentPanelV3';
import { MAX_PANEL_WIDTH } from './containers/RegistrationContainer';

// TODO: reintroduce notifications
function RegisteredSoloPanelUnmemoized() {
  const {
    // notifyMe,
    // isSubscribed,
    // unsubscribe,
    skipCheckIn,
    phase,
    foundTeam,
  } = useTournamentRegistration();
  const { leaveTeam } = useRegistrationFunctions();
  const { uid } = useAuth();
  const checkedIn = isUserCheckedIn({
    userId: uid || undefined,
    team: foundTeam,
  });
  const { id: teamId } = foundTeam!;
  const unregisterSolo = async () => {
    await leaveTeam(teamId);
  };
  return (
    <>
      <TournamentPanelV3
        wrapperSx={{ p: 4, maxWidth: MAX_PANEL_WIDTH, gap: '8px' }}
        title={'REGISTERED SOLO!'}
        description={
          <>
            {!skipCheckIn && (
              <Alert
                severity={checkedIn ? 'success' : 'warning'}
                variant="outlined"
              >
                <Typography
                  variant="subtitle1"
                  color={checkedIn ? 'success.light' : 'warning.light'}
                  sx={{ my: -1, textTransform: 'none', fontWeight: '500' }}
                >
                  {checkedIn
                    ? 'Way to go! You have successfully checked in :)'
                    : 'You will still need to check in during the check-in window.'}
                </Typography>
              </Alert>
            )}
          </>
        }
        Content={
          <Stack direction="row" width="100%" gap="16px">
            {isPreReady(phase) && (
              <LoadingButton
                variant="contained"
                color="error"
                onClick={unregisterSolo}
                fullWidth
              >
                Unregister
              </LoadingButton>
            )}
            {phase === 'checkIn' && !checkedIn && (
              <CheckInButton idToCheckIn={uid || undefined} fullWidth />
            )}
            {/* <SubscribeUpdatesButton
            {...{ notifyMe, isSubscribed, unsubscribe }}
          /> */}
          </Stack>
        }
      />
    </>
  );
}

export const RegisteredSoloPanel = memo(RegisteredSoloPanelUnmemoized);
