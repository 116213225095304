import { Optional } from 'utility-types';
import { v4 as uuidv4, V4Options } from 'uuid';
import { Factory } from '../../../util/Factory';
//import { log } from '../../../util/log';
import {
  Round,
  Cohort,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { Timestamp } from 'firebase-admin/firestore';

export type CohortSettings<T = Timestamp> = Omit<
  Optional<Cohort<T>, 'finishedRoundCount'>,
  'id' | 'rounds'
> & {
  id?: V4Options;
};

//@log
export class CohortFactory<T = Timestamp> extends Factory<
  CohortSettings<T>,
  (roundSegments: Round<T>[]) => CohortSettings<T>
> {
  public build(rounds: Round<T>[]) {
    const settings = this.settings(rounds);
    return {
      rounds,
      ...settings,
      id: uuidv4(settings.id),
    };
  }
}
