import { useMemo } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { isAdmin } from '../../../../functions/src/types/firestore/User/util';
import {
  ADMIN_FILTERS,
  GUEST_FILTERS,
  normalUserFilters,
} from '../../../../functions/src/util/algolia/config/events';

export const useUserEventsFilters = () => {
  const { user } = useAuth();

  return useMemo(() => {
    if (!user?.uid) {
      return GUEST_FILTERS;
    }
    if (isAdmin(user?.email)) {
      return ADMIN_FILTERS;
    }
    return normalUserFilters(user.uid);
  }, [user?.email, user?.uid]);
};
