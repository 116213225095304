export const FIREBASE_URL_PREFIX = 'https://firebasestorage.googleapis.com/';
export const IMAGEKIT_URL_PREFIX = 'https://ik.imagekit.io/blumint';

export function toImageKit(videoUrl: string) {
  if (videoUrl.includes(FIREBASE_URL_PREFIX)) {
    videoUrl = videoUrl.slice(FIREBASE_URL_PREFIX.length);
    return `${IMAGEKIT_URL_PREFIX}/tr:f-webm/${videoUrl}`; // makes imagekit serve the video as WebM
  }
  return videoUrl;
}
