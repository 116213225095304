import { useEffect, useRef } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { GradientIcon } from '../gradients/GradientIcon';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {
  firstScrollableParent,
  scrollToHorizontally,
} from '../../util/scrollToHorizontally';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stepper from '@mui/material/Stepper';
import { memo } from '../../util/memo';

export type PhaseStepsProps = {
  phases: (
    | {
        Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
          muiName: string;
        };
        label: string;
      }
    | undefined
  )[];
  activeIndex: number;
};

export const PhaseSteps = memo(function PhaseStepsUnmemoized({
  phases,
  activeIndex,
}: PhaseStepsProps) {
  const theme = useTheme();
  const activeStepRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const activeStepElement = activeStepRef.current;

    const scrollableParent = firstScrollableParent(activeStepElement);
    if (scrollableParent && activeStepElement) {
      scrollToHorizontally(activeStepElement, scrollableParent);
    }
  }, [activeIndex]);

  return (
    <Stepper
      activeStep={activeIndex}
      sx={{
        '& .MuiStepConnector-line': {
          minWidth: '24px',
        },
        '& .Mui-disabled .MuiStepConnector-line': {
          borderColor: theme.palette.disabled.main,
        },
        ...theme.palette.scrollbars.invisible,
        marginBottom: 4,
        marginTop: 2,
        overflowX: 'scroll',
        overflowY: 'hidden',
      }}
    >
      {phases.map((phase, index) => {
        const { label, Icon } = phase!;
        const isCompleted = index < activeIndex;
        const isActive = index === activeIndex;
        return (
          <Step
            sx={{ whitespace: 'nowrap' }}
            ref={isActive ? activeStepRef : null}
            key={label}
          >
            <StepLabel
              sx={{
                height: 40,
                borderRadius: '100px',
                padding: '8px 24px',
                background:
                  theme.palette.background.elevationSolid[isActive ? 20 : 14],
              }}
              icon={
                <GradientIcon
                  gradientColor={
                    isActive || isCompleted
                      ? 'primary.horizontal'
                      : 'disabled.horizontal'
                  }
                  IconComponent={isCompleted ? CheckCircleIcon : Icon}
                />
              }
            >
              <Typography
                variant="body1"
                color="text.primary"
                sx={theme.lineClamp(1, {
                  textOverflow: 'unset',
                  wrap: false,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                })}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
});
