import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

export const StyledTableCell = styled(TableCell)(() => {
  return {
    padding: '4px 16px',
    border: `1px solid rgba(0, 100, 233, 0.3) !important`,
    [`&.${tableCellClasses.head}`]: {
      textTransform: 'uppercase',
      borderBottom: `2px solid rgba(0, 100, 233, 0.5) !important`,
    },
  };
});
