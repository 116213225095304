import {
  BracketRoundsKeys,
  MatchAggregated,
  Round,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { LinkedListUtil } from './LinkedListUtil';
import { Required } from 'utility-types';
import { EliminationTournament } from './EliminationTournament';
import { MatchFactory, MatchSettings } from './MatchFactory';
import { RoundFactory } from './RoundFactory';
import { transformLoserIndex } from '../../../util/brackets/double-elimination/dropInTransformations';

export class DoubleElimination extends EliminationTournament {
  public readonly bracketLoser: Required<Round, 'matches'>[];
  public readonly grandFinal: Required<Round, 'matches'>[];
  constructor(
    public bracket: Required<Round, 'matches'>[],
    matchFactory: MatchFactory = new MatchFactory(),
    roundFactory: RoundFactory = new RoundFactory(),
  ) {
    super(matchFactory, roundFactory);
    this.bracketLoser = this.germinateBracket(
      this.buildInitialRound(),
      this.roundCount,
    );
    this.grandFinal = [
      this.roundFactory.build([
        this.matchFactory.build(),
        this.matchFactory.build(),
      ]),
    ];
    this.tieBrackets();
    this.reallocatePayouts();
  }

  private buildInitialRound(): Required<Round, 'matches'> {
    const matchesInitial = new Array(this.initialMatchesCount)
      .fill('')
      .map(() => {
        return this.initialFactory.build();
      });
    return this.roundFactory.build(matchesInitial);
  }

  private get initialMatchesCount() {
    return this.bracket[0].matches.length / 2;
  }

  private get roundCount() {
    return 2 * (this.bracket.length - 1);
  }

  private tieBrackets() {
    const numRounds = this.bracket.length;

    for (let roundIndex = numRounds - 1; roundIndex >= 0; roundIndex--) {
      this.tieWinnerBracketRound(roundIndex);
    }
    this.tieGrandFinal();
  }

  private tieWinnerBracketRound(roundIndex: number) {
    const winnerMatches = this.bracket[Number(roundIndex)].matches;
    const bracketLoserRoundIndex = Math.max(2 * roundIndex - 1, 0);

    for (let matchIndex = 0; matchIndex < winnerMatches.length; matchIndex++) {
      this.tieWinnerLoserMatches(
        roundIndex,
        bracketLoserRoundIndex,
        matchIndex,
      );
    }
  }

  private tieWinnerLoserMatches(
    roundIndex: number,
    bracketLoserRoundIndex: number,
    matchIndex: number,
  ) {
    const winnerMatch =
      this.bracket[Number(roundIndex)].matches[Number(matchIndex)];
    const loserMatchIndex =
      roundIndex === 0 ? Math.floor(matchIndex / 2) : matchIndex;
    const loserMatch =
      this.bracketLoser[Number(bracketLoserRoundIndex)].matches[
        Number(
          transformLoserIndex(
            loserMatchIndex,
            roundIndex,
            this.bracketLoser[Number(bracketLoserRoundIndex)].matches.length,
          ),
        )
      ];

    winnerMatch.nextLoser = loserMatch.id;
    const previousKey = loserMatch.previous1 ? 'previous2' : 'previous1';
    loserMatch[`${previousKey}`] = winnerMatch.id;
  }

  private tieGrandFinal() {
    const [winnerFinalMatch, loserFinalMatch] = (
      ['bracket', 'bracketLoser'] as const
    ).map((key) => {
      return this.finalMatchOf(key);
    });
    const [grandFinalMatch, optionalGrandFinalMatch] =
      this.grandFinal[0].matches;

    LinkedListUtil.tieCoalesce(
      winnerFinalMatch,
      loserFinalMatch,
      grandFinalMatch,
    );
    LinkedListUtil.tie(grandFinalMatch, optionalGrandFinalMatch);
  }

  private finalMatchOf(key: BracketRoundsKeys) {
    // eslint-disable-next-line security/detect-object-injection
    return this[key][this[key].length - 1].matches[0];
  }

  private reallocatePayouts() {
    this.assignGrandFinalPayout();
    this.shiftWinnerPayouts();
    this.assignLoserPayouts();
  }

  private assignGrandFinalPayout() {
    this.grandFinal[0].payout = this.bracket[this.bracket.length - 1].payout;
  }

  private shiftWinnerPayouts() {
    for (let i = this.bracket.length - 2; i >= 0; i--) {
      this.bracket[i + 1].payout = this.bracket[Number(i)].payout || [];
    }
  }

  private assignLoserPayouts() {
    for (let i = 0; i < this.bracket.length; i++) {
      if (this.bracketLoser[2 * i]) {
        this.bracketLoser[2 * i].payout = this.bracket[Number(i)].payout || [];
      }
    }
  }

  public static bracketAround(
    bracketWinnerStandalone: Required<Round, 'matches'>[],
    matchSettings?: MatchSettings,
  ) {
    const { bracketLoser, bracket, grandFinal } = new DoubleElimination(
      bracketWinnerStandalone,
      new MatchFactory(matchSettings),
    );
    return { bracketLoser, bracket, grandFinal };
  }

  public nextMatches(
    matches: MatchAggregated[],
    roundIndex: number,
  ): MatchAggregated[] {
    const doCoalesce = roundIndex % 2 === 1;
    const matchIndices = this.getMatchIndices(matches.length, doCoalesce);

    return matchIndices.map((matchIndex) => {
      return this.createNextMatch(matches, matchIndex, doCoalesce);
    });
  }

  private getMatchIndices(countMatches: number, doCoalesce: boolean): number[] {
    return doCoalesce
      ? [...Array(countMatches / 2).keys()].map((i) => {
          return i * 2;
        })
      : [...Array(countMatches).keys()];
  }

  private createNextMatch(
    matches: MatchAggregated[],
    matchIndex: number,
    doCoalesce: boolean,
  ): MatchAggregated {
    const { match1, match2, matchNext } = this.createMatches(
      matches,
      matchIndex,
      doCoalesce,
    );

    if (doCoalesce && match2) {
      LinkedListUtil.tieCoalesce(match1, match2, matchNext);
    } else {
      LinkedListUtil.tieOptimistic(match1, matchNext);
    }
    return matchNext;
  }

  private createMatches(
    matches: MatchAggregated[],
    matchIndex: number,
    doCoalesce: boolean,
  ) {
    const match1 = matches[Number(matchIndex)] || this.matchFactory.build();
    const match2 = doCoalesce
      ? matches[matchIndex + 1] || this.matchFactory.build()
      : null;
    const winners = match2
      ? [match1?.winner, match2?.winner]
      : [match1?.winner];
    const matchNext = this.matchFactory.build(...winners);

    return { match1, match2, matchNext };
  }
}
