import { useEffect, useState } from 'react';
import { EXPLORE_ELEMENT_ID } from '../components/Explore';

export type VerticalVisibility = 'above' | 'below' | 'visible';
export type HorizontalVisibility = 'left' | 'right' | 'visible';

export type VisibilityOnScreenProps = {
  target?: HTMLElement | null;
  scrollableParent?: HTMLElement | null;
};

export function useVisibilityOnScreen({
  target,
  scrollableParent,
}: VisibilityOnScreenProps): {
  vertical: VerticalVisibility;
  horizontal: HorizontalVisibility;
} {
  const [visibility, setVisibility] = useState({
    vertical: 'visible' as VerticalVisibility,
    horizontal: 'visible' as HorizontalVisibility,
  });

  useEffect(() => {
    const exploreElement = document.getElementById(EXPLORE_ELEMENT_ID);

    if (!target || !exploreElement) {
      return;
    }

    const checkVisibility = () => {
      const rect = target.getBoundingClientRect();

      const verticalStatus: VerticalVisibility =
        rect.bottom < 0
          ? 'above'
          : rect.top > window.innerHeight
          ? 'below'
          : 'visible';

      const horizontalStatus: HorizontalVisibility =
        rect.right < 0
          ? 'left'
          : rect.left > window.innerWidth
          ? 'right'
          : 'visible';

      setVisibility({ vertical: verticalStatus, horizontal: horizontalStatus });
    };

    exploreElement.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);
    if (scrollableParent) {
      scrollableParent.addEventListener('scroll', checkVisibility);
    }
    checkVisibility();

    return () => {
      exploreElement.removeEventListener('scroll', checkVisibility);
      window.removeEventListener('resize', checkVisibility);
      if (scrollableParent) {
        scrollableParent.removeEventListener('scroll', checkVisibility);
      }
    };
  }, [target, scrollableParent]);

  return visibility;
}
