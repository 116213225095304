import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { functions as firebaseFunctions } from '../config/firebase-client/functions';

export function callableFactory(
  prefix: (functionName: string) => string,
): <TParams, TResponse>(
  functionName: string,
) => HttpsCallable<TParams, Awaited<TResponse>> {
  return <TParams, TResponse>(functionName: string) => {
    const baseCallable = httpsCallable<TParams, Awaited<TResponse>>(
      firebaseFunctions,
      prefix(functionName),
    );

    return async (data: TParams) => {
      const { datadogRum } = await import('@datadog/browser-rum');
      const sessionId = datadogRum.getInternalContext()?.session_id;
      const extendedData = { ...data, datadogSessionId: sessionId };

      return baseCallable(extendedData);
    };
  };
}
