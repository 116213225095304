import { MatchAggregated } from '../../../types/firestore/Game/Tournament/Bracket';
import { ParticipantTeam } from '../../../types/firestore/Game/Tournament/Participant';
import { MatchFactory } from './MatchFactory';

export class CohortPairer {
  private readonly participants: ParticipantTeam[];
  public matches: MatchAggregated[];

  protected constructor(
    participants: ParticipantTeam[],
    private readonly matchesCount: number,
    private readonly factory: MatchFactory,
  ) {
    this.participants = [...participants];
    this.matches = [];
    this.assign();
  }

  public static pair(
    participants: ParticipantTeam[],
    matchesCount: number,
    factory: MatchFactory,
  ) {
    const pairer = new CohortPairer(participants, matchesCount, factory);
    return pairer.matches;
  }

  public assign() {
    this.assignFirstTeams();
    this.assignSecondTeams();
    this.assignByes();
  }

  private assignFirstTeams() {
    this.matches = [...Array(this.matchesCount).keys()].map(() => {
      return this.factory.build(this.participants.shift());
    });
  }

  private assignSecondTeams() {
    [0, 1].map((startIndex) => {
      return this.assignEveryOther(startIndex);
    });
  }

  private assignEveryOther(startIndex: number) {
    for (let i = startIndex; i < this.matches.length; i += 2) {
      this.assignSecondTeam(this.matches[Number(i)]);
    }
  }

  private assignSecondTeam(match: MatchAggregated) {
    if (this.participants.length) {
      match.team2 = this.participants.shift();
    }
  }

  private assignByes() {
    this.matches.forEach((match) => {
      if (match.team1 && !match.team2) {
        match.winner = match.team1;
      }
    });
  }
}
