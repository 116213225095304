import Box from '@mui/material/Box';
import React, { useCallback, useMemo, useContext } from 'react';
import { memo } from '../../util/memo';
import { LiveVideoCard } from '../cards/LiveVideoCard';
import { VerticalCarousel } from '../algolia/catalogs/VerticalCarousel';
import { SxProps, useTheme } from '@mui/material/styles';
import { SelectableRouted } from '../routing/SelectableRouted';
import { VideoContent } from '../../../functions/src/types/firestore/VideoRegistry';
import { AccordionInfo } from '../AccordionInfo';
import { useSelectedVideoUrl } from '../../contexts/SelectedVideoUrlContext';
import {
  ACCORDION_TITLE_LIVESTREAM,
  AccordionBodyLivestream,
} from './AccordionBodyLivestream';
import { TournamentContext } from '../../contexts/docs/TournamentContext';

export type VideoContentCarouselProps = {
  vods: VideoContent[];
  containerSx: SxProps;
};

export const VideoContentCarouselUnmemoized = ({
  vods,
  containerSx,
}: VideoContentCarouselProps) => {
  const theme = useTheme();
  const { setSelectedVideoUrl: onSelectVideo } = useSelectedVideoUrl();

  const { title: tournamentTitle } = useContext(TournamentContext);

  const changeSelect = useCallback(
    (contentUrl: string, isSelected: boolean) => {
      if (isSelected) {
        onSelectVideo(contentUrl);
      }
    },
    [onSelectVideo],
  );

  const LiveCards = useMemo(() => {
    return vods.map(
      ({ thumbnailUrl, contentTitle, channelTitle, postedAt, contentUrl }) => {
        return {
          key: channelTitle,
          Node: (
            <SelectableRouted
              value={contentUrl}
              onChange={changeSelect}
              queryParamKey="video"
              borderRadius="10px"
            >
              <LiveVideoCard
                thumbnail={thumbnailUrl}
                title={contentTitle}
                username={channelTitle}
                postedAt={postedAt}
                videoUrl={contentUrl}
              />
            </SelectableRouted>
          ),
        };
      },
    );
  }, [changeSelect, vods]);

  return (
    <Box
      sx={{
        ...containerSx,
      }}
    >
      <AccordionInfo
        title={ACCORDION_TITLE_LIVESTREAM}
        body={<AccordionBodyLivestream tournamentTitle={tournamentTitle} />}
      />
      <VerticalCarousel
        Content={LiveCards}
        share={false}
        containerSx={{
          background: theme.palette.background.elevation[3.5],
        }}
        showDivider={false}
        cardSpacing="12px"
      />
    </Box>
  );
};

export const VideoContentCarousel = memo(VideoContentCarouselUnmemoized);
