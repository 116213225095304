//import { log } from '../../../util/log';
import { Timestamp } from 'firebase-admin/firestore';
import {
  Round,
  Cohort,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { DEFAULT_COHORT_SIZE } from '../bracket/EliminationPairer';
import { LinkedListUtil } from '../bracket/LinkedListUtil';
import { CohortFactory } from './CohortFactory';
import { HeatChunker } from './HeatChunker';

export const COHORT_QUALIFIER_TITLES = [
  'Red Qualifier', // 1
  'Blue Qualifier', // 2
  'Green Qualifier', // 3
  'Yellow Qualifier', // 4
  'Orange Qualifier', // 5
  'Purple Qualifier', // 6
  'Pink Qualifier', // 7
  'Teal Qualifier', // 8
  'Black Qualifier', // 9
  'White Qualifier', // 10
  'Gray Qualifier', // 11
  'Lime Qualifier', // 12
  'Maroon Qualifier', // 13
  'Olive Qualifier', // 14
  'Aqua Qualifier', // 15
  'Fuschia Qualifier', // 16
  'Brown Qualifier', // 17
  'Azure Qualifier', // 18
  'Beige Qualifier', // 19
  'Violet Qualifier', // 20
  'Crimson Qualifier', // 21
  'Khaki Qualifier', // 22
  'Cyan Qualifier', // 23
  'Indigo Qualifier', // 24
  'Ivory Qualifier', // 25
  'Lavender Qualifier', // 26
  'Salmon Qualifier', // 27
  'Plum Qualifier', // 28
  'Amber Qualifier', // 29
  'Burgundy Qualifier', // 30
  'Auburn Qualifier', // 31
  'Jade Qualifier', // 32
] as const;

export const COHORT_QUALIFIER_TITLES_LOSER = COHORT_QUALIFIER_TITLES.map(
  (title) => {
    return `${title} (Loser)`;
  },
);

export type BracketChunkerOptions = {
  bracketLoser: boolean;
  grandFinal: boolean;
};

const DEFAULT_OPTIONS: BracketChunkerOptions = {
  bracketLoser: false,
  grandFinal: false,
};

//@log
export class BracketChunker<T = Timestamp> {
  public constructor(
    private readonly cohortFactory = new CohortFactory<T>(),
    private readonly cohortSize: number = DEFAULT_COHORT_SIZE,
    private readonly options = DEFAULT_OPTIONS,
  ) {}

  public chunkIntoCohorts(bracket: Round<T>[]): Cohort<T>[] {
    const heats = this.chunkIntoHeats(bracket);

    const cohortsByHeat = heats.map((heatRounds) => {
      const heatChunker = new HeatChunker<T>(
        heatRounds,
        this.cohortFactory,
        undefined,
        { isDoubleElimination: this.options.bracketLoser },
      );
      return heatChunker.cohorts;
    });
    BracketChunker.tieCohorts(cohortsByHeat);

    const cohortsWithoutTitles = cohortsByHeat.flat();
    return this.title(cohortsWithoutTitles);
  }

  public chunkIntoHeats(bracket: Round<T>[]): Round<T>[][] {
    const heatsCount = Math.ceil(bracket.length / this.roundsPerCohort);
    return [...Array(heatsCount).keys()].map((i) => {
      return bracket.slice(
        i * this.roundsPerCohort,
        (i + 1) * this.roundsPerCohort,
      );
    });
  }

  private get roundsPerCohort(): number {
    const roundsBase = Math.log2(this.cohortSize);
    return !!this.options.bracketLoser ? 2 * roundsBase : roundsBase + 1;
  }

  private static tieCohorts<T = Timestamp>(cohortsByHeat: Cohort<T>[][]) {
    for (let i = 0; i < cohortsByHeat.length - 1; i++) {
      const heatCohorts = cohortsByHeat[Number(i)];
      if (heatCohorts.length > 1) {
        heatCohorts.forEach((cohort) => {
          const cohortNext = heatCohorts[i + 1];
          LinkedListUtil.tieCoalesceMany(cohort, cohortNext);
        });
      }
    }
  }

  private title(cohorts: Cohort<T>[]): Cohort<T>[] {
    const qualifiers = cohorts.slice(0, cohorts.length - 1);
    qualifiers.forEach((qualifier, i) => {
      qualifier.title = this.cohortQualifierTitles[Number(i)];
    });
    const final = cohorts[cohorts.length - 1];
    final.title = !!this.options.grandFinal ? 'Grand Final' : 'Final';
    return [...qualifiers, final];
  }

  private get cohortQualifierTitles() {
    return this.options.bracketLoser
      ? COHORT_QUALIFIER_TITLES_LOSER
      : COHORT_QUALIFIER_TITLES;
  }
}
