type DropInTransformation = (
  matchIndex: number,
  totalMatches: number,
) => number;

export const dropInTransformations: DropInTransformation[] = [
  (matchIndex) => {
    return matchIndex;
  },
  // reverse
  (matchIndex, totalMatches) => {
    return totalMatches - 1 - matchIndex;
  },
  // reverse each half
  (matchIndex, totalMatches) => {
    const middle = Math.floor(totalMatches / 2);
    if (matchIndex < middle) {
      return middle - 1 - matchIndex;
    }
    return totalMatches - 1 - (matchIndex - middle);
  },
  // swap the halves
  (matchIndex, totalMatches) => {
    const middle = Math.floor(totalMatches / 2);
    return matchIndex >= middle ? matchIndex - middle : middle + matchIndex;
  },
];

export const transformLoserIndex = (
  loserMatchIndex: number,
  roundIndex: number,
  countMatches: number,
) => {
  const dropInTransformation =
    dropInTransformations[roundIndex % dropInTransformations.length];
  return dropInTransformation(loserMatchIndex, countMatches);
};
