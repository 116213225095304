import { MatchProvider } from './MatchProvider';
import { MatchCell } from './cell/MatchCell';
import { CellButtonReferee } from './cell/CellButtonReferee';
import { CellButtonDetails } from './cell/CellButtonDetails';
import { useReferee } from 'src/contexts/RefereeContext';
import { useContext, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { MatchAggregated } from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { BracketContext } from '../../../contexts/docs/BracketContext';
import { useMatchDisabled } from '../../../hooks/tournaments/useMatchDisabled';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { CellButtonRevert } from './cell/CellButtonRevert';
import { BracketTeamMover } from '../../../../functions/src/callable/tournament/bracket/BracketTeamMover';
import { isBypassMatch } from '../../../../functions/src/types/firestore/Game/Tournament/Bracket/util/isBypass';
import { PulsateUnreadMatch } from '../../PulsateUnreadMatch';
import { ActiveMatchRouterProvider } from '../../../contexts/ActiveMatchRouterContext';
import Stack from '@mui/material/Stack';

export type MatchBracketProps = {
  isLarge?: boolean;
  match: MatchAggregated<Date>;
};

export const MatchBracket = memo(function MatchBracket({
  isLarge,
  match,
}: MatchBracketProps) {
  const bracket = useContext(BracketContext);
  if (!bracket) {
    throw new Error(
      'MatchBracket must be used within a BracketContext.Provider.',
    );
  }

  const { isMatchDisabled } = useMatchDisabled(bracket);
  const tournament = useContext(TournamentContext);
  const { id: tournamentId, gameId } = tournament;
  const { isReferee } = useReferee();
  const { team1, team2, id, winner } = match;
  const teamMover = new BracketTeamMover<Date>(bracket, id);
  const { nextMatch, nextLoserMatch } = teamMover;

  const showRevertButton = useMemo(() => {
    return (
      // The next match in the winner bracket - which cannot be a bypass - is not finished
      !nextMatch?.winner &&
      // The next match in the loser bracket
      (!nextLoserMatch?.winner || isBypassMatch(nextLoserMatch)) &&
      !isBypassMatch(match)
    );
  }, [match, nextLoserMatch, nextMatch?.winner]);

  const scoringButton = useMemo(() => {
    if (!isReferee) {
      return;
    }
    if (!team1 && !team2) {
      return;
    }
    if (!winner) {
      return <CellButtonReferee gameId={gameId} tournamentId={tournamentId} />;
    }
    if (showRevertButton) {
      return <CellButtonRevert gameId={gameId} tournamentId={tournamentId} />;
    }
    return <></>;
  }, [gameId, isReferee, showRevertButton, team1, team2, tournamentId, winner]);

  const cellButton = useMemo(() => {
    return (
      <Stack direction={'row'}>
        <CellButtonDetails />
        {scoringButton}
      </Stack>
    );
  }, [scoringButton]);

  const isDisabled = useMemo(() => {
    return isMatchDisabled(match);
  }, [isMatchDisabled, match]);

  return (
    <MatchProvider {...match} isMatchDisabled={isDisabled}>
      <PulsateUnreadMatch
        matchId={id}
        sx={{
          borderRadius: '12px',
          transform: 'scaleX(1.03) scaleY(1.06)',
        }}
      >
        <ActiveMatchRouterProvider>
          <MatchCell isLarge={isLarge} Button={cellButton} />
        </ActiveMatchRouterProvider>
      </PulsateUnreadMatch>
    </MatchProvider>
  );
});
