import { ScoreOption } from '../../types/firestore/Game/Competition';
import { SCORE_OPTION_DEFAULT } from '../../types/firestore/Game/Tournament/Heat';
import {
  Result,
  ResultRanked,
} from '../../types/firestore/Game/Tournament/Heat/Result';
import { compareSequentialElements } from '../compareSequentialElements';
import { sortHeatResults } from './sortHeatResults';

export const assignRanks = (
  results: Result[],
  scoreOptions: ScoreOption[] = [SCORE_OPTION_DEFAULT],
): ResultRanked[] => {
  const sortBys = [...scoreOptions].map(({ orderBy }) => {
    return orderBy;
  });
  const sortedResults = sortHeatResults(results, sortBys);
  let rank = 1;
  return sortedResults.map((result, index, arr) => {
    if (index > 0) {
      const scoreComparison = compareSequentialElements(
        result.scores || [],
        arr[index - 1].scores || [],
      );
      if (scoreComparison !== 0) {
        rank += 1;
      }
    }
    return { ...result, rank };
  });
};
