import { useMemo, useEffect } from 'react';
import { useRealtimeChannelGroups } from '../../contexts/RealtimeChannelGroupContext';
import { useMobile } from '../useMobile';
import { useActiveTournamentChannel } from './useActiveTournamentChannel';

export const useOpenGeneralChannelOnLoad = (tournamentId: string) => {
  const { openGeneralChannel } = useActiveTournamentChannel(tournamentId);
  const { realtimeChannelGroups } = useRealtimeChannelGroups();

  const isChannelGroupLoaded = useMemo(() => {
    return realtimeChannelGroups.some(({ groupFilter, permanence }) => {
      return (
        groupFilter[0].tournamentId === tournamentId &&
        permanence === 'temporary'
      );
    });
  }, [realtimeChannelGroups, tournamentId]);

  const isMobile = useMobile();

  useEffect(() => {
    if (isMobile) {
      return;
    }
    if (!isChannelGroupLoaded) {
      return;
    }
    openGeneralChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isChannelGroupLoaded]);
};
