import Stack from '@mui/material/Stack';
import { TeammatesTable } from 'src/components/tournaments/registration/teammates/TeammatesTable';
import { isReadyOrLive } from 'functions/src/types/firestore/Game/Tournament/util';
import { memo } from '../../../util/memo';
import { getTeammates } from '../../../util/tournaments/getTeammates';
import { useAuth } from '../../../contexts/AuthContext';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { AlertSufficientMinTeam } from './AlertSufficientMinTeam';
import { LeaveTeamButton } from './LeaveTeamButton';
import { TournamentPanelV3 } from '../TournamentPanelV3';
import { MAX_PANEL_WIDTH } from './containers/RegistrationContainer';
import Typography from '@mui/material/Typography';
// import { ShareInviteButton } from '../ShareInviteButton';
import { ShareInviteButtonOld } from '../ShareInviteButtonOld';

export const JOINED_TEAM_PANEL_DESCRIPTION = `View the status of your teammates below.`;

// TODO: reintroduce notifications
function JoinedTeamPanelUnmemoized() {
  const { uid } = useAuth();
  const { foundTeam, phase, minTeamSize, continuousRegistration } =
    useTournamentRegistration();

  const teammates = getTeammates({ userId: uid || undefined, foundTeam });
  const teammatesAccepted = teammates?.filter(({ status }) => {
    return status !== 'pending' && status !== 'declined';
  });
  const { name: teamName } = foundTeam!;

  return (
    <TournamentPanelV3
      wrapperSx={{ p: 4, maxWidth: MAX_PANEL_WIDTH, gap: 4 }}
      title={`You’ve joined team ${teamName}!`}
      description={
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography color={'text.secondary'}>
            {JOINED_TEAM_PANEL_DESCRIPTION}
          </Typography>
          {/* <ShareInviteButton /> Revert back once fixed*/}
          <ShareInviteButtonOld />
        </Stack>
      }
      Content={
        <Stack gap={4}>
          {!!teammates && (
            <Stack alignItems={'center'} spacing={2}>
              <TeammatesTable teammates={teammates} />
            </Stack>
          )}
          <Stack spacing={4} width="100%">
            {continuousRegistration &&
              isReadyOrLive(phase) &&
              !!teammatesAccepted &&
              !!minTeamSize && (
                <AlertSufficientMinTeam
                  teammates={teammatesAccepted}
                  minTeamSize={minTeamSize}
                />
              )}
            <LeaveTeamButton />
          </Stack>
        </Stack>
      }
    />
  );
}
export const JoinedTeamPanel = memo(JoinedTeamPanelUnmemoized);

//  {/* TODO: Show once Tournament Alerts are ready */}
//             {/* <SubscribeUpdatesButton
//             {...{ notifyMe, isSubscribed, unsubscribe }}
//           /> */}
