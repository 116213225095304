import { useCallback, useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import { MarkdownInput } from '../generic/MarkdownInput';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import { MARKDOWN_LENGTH_CONSTRAINTS } from '../../../../functions/src/util/organizer/constants';
import { useTheme } from '@mui/material/styles';

export type MarkdownAccordionInputListItemProps = {
  isEditing: boolean;
  item: Record<string, string> & { id: string };
  titleKey: string;
  bodyKey: string;
  setIsEditing: (isEditing: boolean) => void;
  handleChange: (key: string, value: string) => void;
  handleDelete: () => void;
  fieldName: string;
};

// TODO: validation is a bit scrappy here.
export const MarkdownAccordionInputListItem = memo(
  function MarkdownAccordionInputListItemUnmemoized({
    isEditing,
    item,
    titleKey,
    bodyKey,
    setIsEditing,
    handleChange,
    handleDelete,
    fieldName,
  }: MarkdownAccordionInputListItemProps) {
    const [error, setError] = useState(false);
    const onError = useCallback((markdownError: boolean) => {
      setError(markdownError);
    }, []);
    const bodyLengthConstraints =
      MARKDOWN_LENGTH_CONSTRAINTS[fieldName][bodyKey];
    const titleLengthConstraints =
      MARKDOWN_LENGTH_CONSTRAINTS[fieldName][titleKey];
    const theme = useTheme();
    const titleError = useMemo(() => {
      const value = item?.[titleKey];
      const { min, max } = titleLengthConstraints;
      return !!value && min <= value.length && value.length <= max
        ? ''
        : `Please fill in this section. It should be between ${min} and ${max} characters in length.`;
      //TODO: error does not update properly without inclusion of isEditing - memoization error?
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, titleKey, titleLengthConstraints, isEditing]);
    return (
      <Accordion
        variant="outlined"
        sx={{
          borderRadius: '10px',
          borderColor: error ? 'error.main' : undefined,
          '& .MuiButtonBase-root': {
            borderRadius: '10px',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: `${theme.palette.background.elevation['11']}`,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={(e) => {
              return e.stopPropagation();
            }}
            width={'100%'}
          >
            <Stack direction="row" alignItems="center">
              <TextField
                variant="standard"
                defaultValue={item[titleKey]}
                onBlur={() => {
                  return setIsEditing(true);
                }}
                helperText={titleError}
                error={!!titleError}
                onChange={(e) => {
                  return handleChange(titleKey, e.target.value);
                }}
              />
            </Stack>
            <Stack direction="row" alignItems="center">
              <GradientIconButton
                gradientColor="primary.horizontal"
                onClick={() => {
                  return handleDelete();
                }}
                sx={{ alignSelf: 'end', width: 20, height: 20 }}
                size="small"
                IconComponent={ClearIcon}
              />
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownInput
            minLength={bodyLengthConstraints.min}
            maxLength={bodyLengthConstraints.max}
            value={item[bodyKey]}
            onChange={(value) => {
              return handleChange(bodyKey, value);
            }}
            onError={onError}
            placeholder={`Enter ${item[titleKey]}`}
          />
        </AccordionDetails>
      </Accordion>
    );
  },
);
