import { memo } from '../../util/memo';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { GradientIcon } from './GradientIcon';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const colorToGradient = {
  primary: 'primary.verticalInverse',
  secondary: 'secondary.verticalInverse',
  error: 'error.verticalInverse',
  warning: 'warning.verticalInverse',
  info: 'info.verticalInverse',
  success: 'success.verticalInverse',
  disabled: 'disabled.vertical',
};

export const GradientCheckbox = memo(function GradientCheckboxUnmemoized(
  props: CheckboxProps,
) {
  const gradientColor = colorToGradient[`${props.color}`];
  return (
    <Checkbox
      {...props}
      size={props.size}
      icon={
        <GradientIcon
          size={props.size}
          gradientColor={gradientColor}
          IconComponent={CheckBoxOutlineBlankOutlinedIcon}
        />
      }
      checkedIcon={
        <GradientIcon
          size={props.size}
          gradientColor={gradientColor}
          IconComponent={CheckBoxIcon}
        />
      }
      indeterminateIcon={
        <GradientIcon
          size={props.size}
          gradientColor={gradientColor}
          IconComponent={IndeterminateCheckBoxIcon}
        />
      }
    />
  );
});
