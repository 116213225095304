import { useCallback } from 'react';
import { DataGridProps, GridRowModel } from '@mui/x-data-grid';
import { useReferee } from 'src/contexts/RefereeContext';
import { TableToolbar } from 'src/components/tournaments/heats-leaderboards/referee/TableToolbar';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { ScoreOption } from '../../../functions/src/types/firestore/Game/Competition';

export const useLeaderboardEditing = (
  scoreOptions?: ScoreOption[],
): Partial<DataGridProps> => {
  const theme = useTheme();
  const { setIsUpdating, isReferee } = useReferee();
  const confirm = useCallback(
    async ({ resolve, reject, newRow, oldRow }) => {
      try {
        const { updateResult } = await import(
          '../../firebaseCloud/tournament/updateResult'
        );
        setIsUpdating(true);
        await updateResult({
          fieldsUpdated: {
            id: newRow.id,
            scores: newRow.scores,
          },
        });
        resolve(newRow);
      } catch (error) {
        reject(oldRow);
      } finally {
        setIsUpdating(false);
      }
    },
    [setIsUpdating],
  );

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) => {
      return new Promise<GridRowModel>((resolve, reject) => {
        const field = Object.keys(newRow).find((key) => {
          return scoreOptions?.some((option) => {
            return option.name.toLowerCase() === key;
          });
        });

        const newRowResolved = { ...newRow, scores: newRow[String(field)] };
        const oldRowResolved = { ...oldRow, scores: oldRow[String(field)] };
        if (newRowResolved.scores !== oldRowResolved.scores) {
          confirm({
            resolve,
            reject,
            newRow: newRowResolved,
            oldRow: oldRowResolved,
          });
        } else {
          resolve(oldRow);
        }
      });
    },
    [confirm, scoreOptions],
  );

  const isCellEditable = useCallback(
    ({ field }) => {
      const isEditable = (scoreOptions || []).some(({ name }) => {
        return name.toLowerCase() === field?.toLowerCase();
      });
      return isEditable;
    },
    [scoreOptions],
  );

  // attempting to memoize this gives a React error 'should have a queue'
  // we could file an issue for this but I will leave it as is for now
  // as it could also be an MUI issue that may be resolved when upgrading.
  return {
    getRowHeight: isReferee
      ? (_params) => {
          return 'auto';
        }
      : undefined,

    experimentalFeatures: { newEditingApi: true },
    isCellEditable,
    processRowUpdate,
    sx: {
      '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
        backgroundColor: `${theme.palette.background.light}`,
      },
    },
    editMode: isReferee ? 'cell' : undefined,
    components: isReferee
      ? {
          Toolbar: TableToolbar,
          BaseButton: ({ children, ...rest }) => {
            return (
              <Button
                {...rest}
                variant="contained"
                sx={{ height: 40, p: 2, m: 1 }}
              >
                {children}
              </Button>
            );
          },
        }
      : undefined,

    componentsProps: {
      panel: {
        sx: {
          '& .MuiDataGrid-paper': {
            backgroundColor: theme.palette.background.dark,
          },
          '& .MuiDataGrid-panelHeader': {
            display: 'none',
          },
          '& .MuiTypography-root': {
            color: theme.palette.primary.main,
            fontSize: 20,
          },
          '& .MuiDataGrid-filterForm': {
            bgcolor: theme.palette.primary.main,
          },
        },
      },
    },
  };
};
