import { MatchAggregated } from '../../../types/firestore/Game/Tournament/Bracket';
import { ParticipantTeam } from '../../../types/firestore/Game/Tournament/Participant';
import { v4 as uuidv4, V4Options } from 'uuid';
import { Optional } from 'utility-types';
import { Factory } from '../../../util/Factory';
//import { log } from '../../../util/log';

export type MatchSettings = Omit<
  Optional<MatchAggregated, 'status'>,
  'id' | 'team1' | 'team2'
> & {
  id?: V4Options;
};

//@log
export class MatchFactory extends Factory<
  MatchSettings,
  (team1?: ParticipantTeam, team2?: ParticipantTeam) => MatchSettings
> {
  // TODO set startTime, maxDelay, streamUrl
  public build(
    team1?: ParticipantTeam,
    team2?: ParticipantTeam,
  ): MatchAggregated {
    const settings = this.settings(team1, team2);
    const match: MatchAggregated = {
      status: 'inactive',
      ...settings,
      id: uuidv4(settings.id),
    };
    if (team1) {
      match.team1 = team1;
    }
    if (team2) {
      match.team2 = team2;
    }
    return match;
  }
}
