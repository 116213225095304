import { embedYoutubeVideo } from '../../functions/src/util/embedYoutubeVideo';
import { useEmbedTwitchVideo } from './useEmbedTwitchVideo';

export const useEmbedVideoUrl = (videoUrl?: string) => {
  const embeddedUrl = useEmbedTwitchVideo(videoUrl);
  if (embeddedUrl) {
    return embeddedUrl;
  }
  if (!videoUrl) {
    return undefined;
  }
  if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
    return embedYoutubeVideo(videoUrl);
  }

  throw new Error('Unsupported video platform');
};
