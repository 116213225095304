import { useDocSnapshot } from './firestore/useDocSnapshot';
import { VideoRegistry } from '../../functions/src/types/firestore/VideoRegistry';
import { useMemo } from 'react';

export const usePlatformVideos = (path: string) => {
  const videoRegistryDocId = path.replace(/\//g, '-');
  const videoRegistryPath = `VideoRegistry/${videoRegistryDocId}`;

  const initialData = useMemo(() => {
    return {
      id: videoRegistryDocId,
      twitch: [],
      youtube: [],
      twitchExpired: [],
      youtubeExpired: [],
    };
  }, [videoRegistryDocId]);

  const videoRegistry = useDocSnapshot<VideoRegistry>(
    videoRegistryPath,
    initialData,
  );

  const videos = useMemo(() => {
    const twitchVideos = videoRegistry?.twitch || [];
    const youtubeVideos = videoRegistry?.youtube || [];

    const sortedVideos = [...twitchVideos, ...youtubeVideos].sort((a, b) => {
      return (b.liveCount || 0) - (a.liveCount || 0);
    });

    return sortedVideos;
  }, [videoRegistry]);

  return videos;
};
