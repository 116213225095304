import { memo } from '../../../../util/memo';
import { useHover } from '../../../../contexts/HoverContext';
import Stack from '@mui/material/Stack';
import { useMatchPermissions } from '../../../../hooks/tournaments/useMatchPermissions';
import { useAuth } from '../../../../contexts/AuthContext';
import { UploadedBy } from './UploadedBy';
import { ScreenshotImageHoverProps } from './ScreenshotImageHover';
import { useScreenshotActions } from '../../../../hooks/tournaments/useScreenshotActions';

export type ScreenshotHoverButtons = Pick<
  ScreenshotImageHoverProps,
  'screenshotUrl'
> & {
  uploaderUid: string;
};

const ScreenshotHoverButtonsUnmemoized = ({
  uploaderUid,
  screenshotUrl,
}: ScreenshotHoverButtons) => {
  const { isHovered } = useHover();

  const { uid } = useAuth();

  const isUserUploader = uid === uploaderUid;

  const { isTournamentAdmin } = useMatchPermissions();

  const renderEditButtons = isUserUploader || isTournamentAdmin;

  const ButtonsExplained = useScreenshotActions(screenshotUrl);

  return (
    <Stack
      sx={{
        display: !isHovered ? 'none' : 'flex !important',
        position: 'absolute',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        top: 0,
        bottom: 0,
        right: 0,
        p: 1,
      }}
    >
      <Stack direction="row" alignItems="center">
        {ButtonsExplained['report']}
        {renderEditButtons && (
          <>
            {ButtonsExplained['update']}
            {ButtonsExplained['delete']}
          </>
        )}
      </Stack>
      <UploadedBy uploaderUid={uploaderUid} />
    </Stack>
  );
};

export const ScreenshotHoverButtons = memo(ScreenshotHoverButtonsUnmemoized);
