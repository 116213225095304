import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { generateBracketGrandFinal } from 'src/util/bracket/generateBracketGrandFinal';
import { BracketProps } from './Bracket';
import { OptionalWrapper } from 'src/components/OptionalWrapper';
import { BracketBackground } from './BracketBackground';
import { memo } from '../../../util/memo';

export const BracketGrandFinal = memo(function BracketGrandFinalUnmemoized({
  rounds,
}: BracketProps) {
  const { matches } = generateBracketGrandFinal(rounds);
  const cellDisabled = !(
    matches[1]?.match.props.match.team1 || matches[1]?.match.props.match.team2
  );
  return (
    <BracketBackground roundsLength={rounds.length} variant={'grandFinal'}>
      {matches.map(({ match, matchId }, index) => {
        return (
          <OptionalWrapper
            key={matchId}
            doWrap={index === 1 && cellDisabled}
            Wrapper={
              <Tooltip
                title={`A second match is to be played if the competitor from the winner's bracket loses the first match.`}
                arrow
              >
                <></>
              </Tooltip>
            }
          >
            <Box
              sx={{
                opacity: index === 1 && cellDisabled ? '40%' : '100%',
              }}
            >
              {match}
            </Box>
          </OptionalWrapper>
        );
      })}
    </BracketBackground>
  );
});
