import {
  MatchAggregated,
  Round,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { v4 as uuidv4, V4Options } from 'uuid';
import { Required } from 'utility-types';
import { Factory } from '../../../util/Factory';
import { Token } from '../../../types/firestore/User/Payout';

//import { log } from '../../../util/log';

export type RoundSettings = Omit<
  Round,
  'id' | 'maxMatchCount' | 'finishedMatchCount' | 'matches' | 'payout'
> & {
  id?: V4Options;
  payouts?: { tokens: Token[] }[];
};

//@log
export class RoundFactory extends Factory<
  RoundSettings,
  (matches: MatchAggregated[]) => RoundSettings
> {
  // TODO set startTime, maxDelay, streamUrl
  public build(matches: MatchAggregated[]): Required<Round, 'matches'> {
    const settings = this.settings(matches);

    const finishedMatches = matches.filter((match) => {
      return !!match.winner;
    });

    const { payouts = undefined, ...rest } = settings;

    return {
      matches: matches,
      maxMatchCount: matches.length,
      finishedMatchCount: finishedMatches.length,
      ...rest,
      payout:
        !!payouts && !!payouts[Math.log2(matches.length)]
          ? payouts[Math.log2(matches.length)].tokens
          : [],
      id: uuidv4(),
    };
  }
}
