import { useLocation } from '../contexts/useLocation';

export const useEmbedTwitchVideo = (url?: string) => {
  const { href } = useLocation();
  if (!url || url.includes('youtube.com') || url.includes('youtu.be')) {
    return undefined;
  }
  const newUrl = new URL(url);
  const parentDomain = href.split('/')[2];
  const pathSegments = newUrl.pathname.split('/').filter((segment) => {
    return segment;
  });

  if (pathSegments.length === 0) {
    throw new Error('Invalid Twitch URL');
  }

  const channelName = pathSegments[0];
  return `https://player.twitch.tv/?channel=${channelName}&parent=${parentDomain}`;
};
