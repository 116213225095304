import { memo } from '../../../../util/memo';
import { TableBackbone } from 'src/components/StyledTable/TableBackbone';
import { StyledTableRow } from 'src/components/StyledTable/StyledTableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { TeamMemberDetails } from './TeamMemberDetails';
import { Member } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { createRowKey } from 'src/util/bracket/createRowKey';
import { SxProps } from '@mui/material/styles';

export const CELL_STYLES = {
  left: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    width: '50%',
    border: 'none',
  },
  right: {
    p: 2,
    textAlign: 'right',
    direction: 'revert',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-end',
    width: '50%',
    border: 'none',
  },
};

export type MatchDetailsTeamsProps = {
  rowsData: {
    team1Member?: Member;
    team2Member?: Member;
  }[];
  sx?: SxProps;
};

export const MatchDetailsTeams = memo(function MatchDetailsTeamsUnmemoized({
  rowsData,
  sx,
}: MatchDetailsTeamsProps) {
  return (
    <>
      <TableBackbone
        sxContainer={{
          border: undefined,
          height: '100%',
          '&:hover': {
            border: 'none',
          },
          ...sx,
        }}
      >
        <TableBody>
          {rowsData.map(({ team1Member, team2Member }) => {
            return (
              <StyledTableRow
                key={createRowKey(team1Member, team2Member)}
                sx={{ display: 'flex', flex: 1 }}
              >
                <TableCell sx={CELL_STYLES.left}>
                  {!!team1Member && <TeamMemberDetails member={team1Member} />}
                </TableCell>
                <TableCell sx={CELL_STYLES.right}>
                  {!!team2Member && <TeamMemberDetails member={team2Member} />}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </TableBackbone>
    </>
  );
});
