import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import { useRef, createRef, useMemo, useState, useCallback } from 'react';
import { useConfirmDialog } from 'src/hooks/useConfirmDialog';
import { useMatchActions } from 'src/hooks/useMatchActions';
import { MatchHeader } from './MatchHeader';
import { MatchControlButtons } from './MatchControlButtons';
import { MatchAutomationControls } from './MatchAutomationControls';
import { MatchAggregated } from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { MatchScorer } from './MatchScorer';
import { useMatch } from '../tournaments/match/MatchProvider';
import { MatchDetailsTeams } from '../tournaments/match/details/MatchDetailsTeams';
import { generateRowData } from '../../util/bracket/generateRowData';
import { extractTeamName } from '../../../functions/src/util/tournaments/extractTeamName';

export type MatchDrawerMatch = Pick<MatchAggregated<Date>, 'id' | 'startTime'> &
  Required<Pick<MatchAggregated<Date>, 'team1' | 'team2'>>;

export type MatchDrawerProps = {
  gameId: string;
  tournamentId: string;
  onClose: () => void;
};

const MatchDrawerUnmemoized = ({
  onClose,
  gameId,
  tournamentId,
}: MatchDrawerProps) => {
  const {
    team1,
    team2,
    id: matchId,
    bestOf,
    team1Scores,
    team2Scores,
    matchUrl,
  } = useMatch();

  const { declareWinner, delayMatch, cancelMatch, recreateMatch } =
    useMatchActions({
      gameId,
      matchId,
      tournamentId,
    });

  const { open: openConfirmDialog, close: closeConfirmDialog } =
    useConfirmDialog(matchId);

  const teams = useMemo(() => {
    const team1Formatted = team1 ? [team1] : [];
    const team2Formatted = team2 ? [team2] : [];
    return [...team1Formatted, ...team2Formatted];
  }, [team1, team2]);
  const scores = useMemo(() => {
    const team1ScoresFormatted = team1Scores?.length ? [team1Scores] : [];
    const team2ScoresFormatted = team2Scores?.length ? [team2Scores] : [];
    return [...team1ScoresFormatted, ...team2ScoresFormatted];
  }, [team1Scores, team2Scores]);

  const teamRefs = useRef(
    teams.map(() => {
      return createRef<HTMLInputElement>();
    }),
  );
  const gameScores = teamRefs.current.map((ref) => {
    return parseInt(!!ref.current ? ref.current.value : '0');
  });

  const [scoreInput, setScoreInput] = useState<number[][]>([[], []]);
  const changeScore = useCallback(
    (teamIndex: number, gameIndex: number, score: number) => {
      const updatedScores = [...scoreInput];
      updatedScores[Number(teamIndex)][Number(gameIndex)] = score;
      setScoreInput(updatedScores);
    },
    [scoreInput],
  );
  const duplicateScores = scoreInput[0].some((_, index) => {
    const valuesAtIndex = scoreInput.map((score) => {
      return score[Number(index)];
    });
    const uniqueValues = new Set(valuesAtIndex);
    return uniqueValues.size !== scoreInput.length;
  });

  //todo: refactor

  const confirm = useCallback(
    (action: 'delay' | 'cancel' | 'recreate') => {
      const matchup =
        teams
          .map((team) => {
            return extractTeamName(team);
          })
          .join(' and ') + '?';

      switch (action) {
        case 'delay':
          return openConfirmDialog({
            title: 'Confirming delay',
            description: `Are you sure you want to delay the match between ${matchup}?`,
            confirmFunction: async () => {
              await delayMatch();
            },
          });
        case 'cancel':
          return openConfirmDialog({
            title: 'Confirming cancellation',
            description: `Are you sure you want to cancel the match between ${matchup}}?`,
            confirmFunction: async () => {
              await cancelMatch();
            },
          });
        case 'recreate':
          return openConfirmDialog({
            title: 'Recreating match',
            description: `Are you sure you want to recreate the match between ${matchup}? The existing url is ${matchUrl}.`,
            confirmFunction: async () => {
              await recreateMatch();
              closeConfirmDialog();
              onClose();
            },
          });
        default:
      }
    },
    [
      cancelMatch,
      closeConfirmDialog,
      delayMatch,
      matchUrl,
      onClose,
      openConfirmDialog,
      recreateMatch,
      teams,
    ],
  );

  const setScores = useCallback(() => {
    const scoresFromRefs = gameScores.map((teamGameScore, index) => {
      return [teamGameScore, ...(scores[Number(index)] ?? [])];
    });
    const winnerIndex = gameScores.indexOf(Math.max(...gameScores));
    const winnerId = teams[Number(winnerIndex)]?.id;
    if (!winnerId) {
      console.warn('Could not determine a winner from these scores');
      return;
    }

    return openConfirmDialog({
      title: 'Are you sure the scores are correct?',
      confirmFunction: async () => {
        await declareWinner(bestOf, winnerId, scoresFromRefs);
        closeConfirmDialog();
        onClose();
      },
    });
  }, [
    bestOf,
    closeConfirmDialog,
    declareWinner,
    gameScores,
    onClose,
    openConfirmDialog,
    scores,
    teams,
  ]);

  const teamNames = useMemo(() => {
    if (!teams.length) {
      return;
    }
    return teams.map((team) => {
      return extractTeamName(team);
    });
  }, [teams]);

  const rowsData = generateRowData(team1, team2);
  return (
    <Stack sx={{ mt: 16 }} spacing={4}>
      {teamNames?.every((team): team is string => {
        return !!team;
      }) && <MatchHeader teamNames={teamNames} />}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {!!rowsData.length && <MatchDetailsTeams rowsData={rowsData} />}
      </Box>
      {teamNames?.every((team): team is string => {
        return !!team;
      }) && (
        <MatchScorer
          teamNames={teamNames}
          teamRefs={teamRefs}
          setGameScore={setScores}
          onScoreChange={changeScore}
          disabled={duplicateScores}
        />
      )}

      <MatchControlButtons handleConfirm={confirm} />
      {matchUrl && <MatchAutomationControls handleConfirm={confirm} />}
    </Stack>
  );
};

export const MatchDrawer = memo(MatchDrawerUnmemoized);
