import { ReactNode, useMemo } from 'react';
import { OptionalWrapper } from '../../components/OptionalWrapper';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

export const useWrapWithTooltips = (
  components: Record<string, JSX.Element>,
  libraryToSearch: Record<any, ReactNode>,
) => {
  return useMemo(() => {
    return Object.entries(components).reduce((acc, curr) => {
      const [componentKey, element] = curr;
      const tooltipTitle = libraryToSearch?.[String(componentKey)];

      acc[String(componentKey)] = (
        <OptionalWrapper
          doWrap={!!tooltipTitle}
          Wrapper={
            <Tooltip
              arrow
              title={tooltipTitle}
              placement="top-end"
              leaveTouchDelay={0}
            >
              <Box width={'100%'}></Box>
            </Tooltip>
          }
        >
          {element}
        </OptionalWrapper>
      );
      return acc;
    }, {} as Record<any, JSX.Element>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [components]);
};
