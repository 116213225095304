import { memo } from '../../../../util/memo';
import Typography from '@mui/material/Typography';
import { StyledTableRow } from 'src/components/StyledTable/StyledTableRow';
import { StyledTableCell } from 'src/components/StyledTable/StyledTableCell';
import { TeammatesTableMemberProps } from './TeammatesTable';
import { isPreReady } from '../../../../../functions/src/types/firestore/Game/Tournament/util';

export type TeammateRowRandomProps = Pick<
  TeammatesTableMemberProps,
  'isCaptain' | 'phase'
> & {
  continuousRegistration?: boolean;
};
const TeammateRowRandomUnmemoized = ({
  isCaptain,
  phase,
  continuousRegistration,
}: TeammateRowRandomProps) => {
  return (
    <StyledTableRow sx={{ width: '100%', height: '42px' }}>
      <StyledTableCell>
        <Typography
          color="text.secondary"
          sx={{ fontStyle: 'italic', pb: 0.5 }}
        >
          Reassignee
        </Typography>
      </StyledTableCell>
      <StyledTableCell />
      {(isPreReady(phase) || continuousRegistration) && <StyledTableCell />}
      {isCaptain && phase === 'checkIn' && <StyledTableCell />}
    </StyledTableRow>
  );
};
export const TeammateRowRandom = memo(TeammateRowRandomUnmemoized);
