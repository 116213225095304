import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GradientTypography } from '../../gradients/GradientTypography';
import { memo } from '../../../util/memo';

export type ParticipantFillProps = {
  currentFill: number;
  maxFill: number;
};

function ParticipantFillUnmemoized({
  currentFill,
  maxFill,
}: ParticipantFillProps) {
  const fill = currentFill > maxFill ? maxFill : currentFill;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <GradientTypography
        variant="h6"
        gradientColor="primary.vertical"
        sx={{
          textTransform: 'uppercase',
          pr: 2,
          fontWeight: 600,
        }}
      >
        Participants
      </GradientTypography>
      <Typography variant="h6">
        {fill}/{maxFill}
      </Typography>
    </Box>
  );
}

export const ParticipantFill = memo(ParticipantFillUnmemoized);
