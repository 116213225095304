import { FC, useState, useEffect } from 'react';
import { memo } from '../../util/memo';
import { Tournament } from 'functions/src/types/firestore/Game/Tournament';
import { Heat } from 'functions/src/types/firestore/Game/Tournament/Heat';
import { Bracket } from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';
import { FullTournamentDetails } from './FullTournamentDetails';
import { useDynamic } from '../../hooks/useDynamic';
import { SelectionContentProps } from '../routing/SelectionRouted';
import Stack from '@mui/material/Stack';
import { LottieLoader } from '../LottieLoader';

type TournamentData = {
  tournament: Tournament<Date>;
  heats: Heat<Date>[];
  bracket: Bracket<Date> | undefined;
};

const tournamentConverter =
  ConverterFactory.buildDateConverter<Tournament<Date>>();
const heatConverter = ConverterFactory.buildDateConverter<Heat<Date>>();
const bracketConverter = ConverterFactory.buildDateConverter<Bracket<Date>>();

const FullTournamentFetchedUnmemoized: FC<SelectionContentProps> = ({
  value: rawTournamentPath,
  onReady,
}) => {
  // TODO: The following is a temporary fix. Someone else is accidently including the question mark,
  // whether from the share button not working, some code updating the router state incorrectly,
  // the middleware, or some user messing with the URL
  const tournamentPath = rawTournamentPath.split('?')[0];

  const heatsPath = `${tournamentPath}/Heat`;
  const bracketPath = `${tournamentPath}/Bracket/${
    tournamentPath.split('/')[3]
  }`;

  const firestoreModule = useDynamic(
    import('../../config/firebase-client/firestore'),
  );
  const firebaseFirestoreModule = useDynamic(import('firebase/firestore'));

  const [tournamentData, setTournamentData] = useState<
    TournamentData | undefined
  >(undefined);

  useEffect(() => {
    setTournamentData(undefined);

    const fetchData = async () => {
      const { fetchFullTournament } = await import(
        '../../firebaseCloud/tournament/fetchFullTournament'
      );

      const {
        data: {
          tournament: tournamentUnconverted,
          heats: heatsUnconverted,
          bracket: bracketUnconverted,
        },
      } = await fetchFullTournament({ tournamentPath });

      setTournamentData({
        tournament: tournamentConverter.convertData(tournamentUnconverted),
        heats: heatsUnconverted.map((heatUnconverted) => {
          return heatConverter.convertData(heatUnconverted);
        }),
        bracket: bracketUnconverted
          ? bracketConverter.convertData(bracketUnconverted)
          : undefined,
      });

      onReady();
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firestoreModule,
    firebaseFirestoreModule,
    tournamentPath,
    heatsPath,
    bracketPath,
  ]);

  if (!tournamentData) {
    return (
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          p: 8,
        }}
      >
        <LottieLoader sx={{ width: '40px', height: '40px' }} />
      </Stack>
    );
  }

  const { tournament, heats, bracket } = tournamentData;

  return (
    <FullTournamentDetails
      tournament={tournament}
      tournamentPath={tournamentPath}
      heats={heats}
      heatsPath={heatsPath}
      bracket={bracket}
      bracketPath={bracketPath}
    />
  );
};

export const FullTournamentFetched = memo(FullTournamentFetchedUnmemoized);
