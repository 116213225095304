import { useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useMatch } from '../../components/tournaments/match/MatchProvider';
import { membersOfMatch } from '../../../functions/src/util/tournaments/membersOfMatch';
import { Team } from '../../../functions/src/types/firestore/Game/Tournament/Guestlist';
import { useTournamentPermissions } from './useTournamentPermissions';

export const useMatchPermissions = () => {
  const { uid } = useAuth();
  const { team1, team2 } = useMatch();

  const userIdsMatch = useMemo(() => {
    return membersOfMatch(
      [team1, team2].filter((team) => {
        return !!team;
      }) as Team<Date>[],
    );
  }, [team1, team2]);

  const { isTournamentAdmin } = useTournamentPermissions();

  const canEditScreenshot = useMemo(() => {
    return isTournamentAdmin || (uid && userIdsMatch?.includes(uid));
  }, [isTournamentAdmin, uid, userIdsMatch]);

  return {
    isTournamentAdmin,
    canEditScreenshot,
  };
};
