import { useCallback, FC } from 'react';
import { memo } from '../../util/memo';
import Button, { ButtonProps } from '@mui/material/Button';
import { useGuardSignIn } from '../../hooks/useGuardSignIn';
import { useAuth } from '../../contexts/AuthContext';
import { useCreateEventWizard } from '../../hooks/organizer/useCreateEventWizard';
import Box from '@mui/material/Box';

export type CreateEventButtonProps = Omit<ButtonProps, 'onClick'>;

const CreateEventButtonUnmemoized: FC<CreateEventButtonProps> = (props) => {
  const { uid } = useAuth();
  const { openSignInGuard } = useGuardSignIn();
  const { open: openCreateEventDialog } = useCreateEventWizard();

  const createEvent = useCallback(() => {
    if (!uid) {
      openSignInGuard('Create an Event?');
      return;
    }
    openCreateEventDialog();
  }, [uid, openCreateEventDialog, openSignInGuard]);

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={createEvent}
        {...props}
      >
        Create event
      </Button>
    </Box>
  );
};

export const CreateEventButton = memo(CreateEventButtonUnmemoized);
