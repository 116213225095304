import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { GradientTypography } from '../../gradients/GradientTypography';
import { TeamAvatarRegistration } from './TeamAvatarRegistration';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { TeamNameInput } from './TeamNameInput';
import { getTeammates } from '../../../util/tournaments/getTeammates';
import { useAuth } from '../../../contexts/AuthContext';
import { CheckInButton } from '../check-in/CheckInButton';
import { AlertSufficientMinTeam } from './AlertSufficientMinTeam';
import { useMemo } from 'react';
import { TeammatesTable } from './teammates/TeammatesTable';
import { isReadyOrLive } from '../../../../functions/src/types/firestore/Game/Tournament/util';
import { LockTeamCheck } from './LockTeamCheck';
import { AdapexAdContainer } from '../../ads/AdapexAdContainer';
import { REGISTRATION_AD } from '../../../../functions/src/util/ads/adIds';
import { TEAM_MEMBERS_DIMENSIONS } from '../../../../functions/src/config/ads/adDimensions';
import { AD_BORDER_RADIUS } from '../../algolia/catalog-wrappers/GameHorizontalCarousel';

export function RegisteredTeamEditing() {
  const { uid } = useAuth();
  const {
    id: tournamentId,
    phase,
    foundTeam,
    continuousRegistration,
    minTeamSize,
    preventMerging,
  } = useTournamentRegistration();
  const teammates = getTeammates({ userId: uid || undefined, foundTeam });
  const teamNotCheckedIn = useMemo(() => {
    return teammates?.some((member) => {
      return !member.checkedIn;
    });
  }, [teammates]);

  const teammatesAccepted = useMemo(() => {
    return teammates?.filter(({ status }) => {
      return status !== 'pending' && status !== 'declined';
    });
  }, [teammates]);

  const updatedContainerId = `${REGISTRATION_AD}-${tournamentId}`;

  return (
    <>
      <Typography variant="h6" color="text.secondary">
        Continue editing your team below.
      </Typography>
      <Stack
        direction="column"
        spacing={6}
        sx={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <GradientTypography
            variant="h6"
            textTransform="uppercase"
            gradientColor="primary.vertical"
          >
            Team Avatar
          </GradientTypography>
          <TeamAvatarRegistration />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <GradientTypography
            variant="h6"
            textTransform="uppercase"
            gradientColor="primary.vertical"
          >
            Team Name
          </GradientTypography>
          <TeamNameInput />
        </Box>
      </Stack>
      {teammates && (
        <Stack spacing={4}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <GradientTypography
              variant="h6"
              textTransform="uppercase"
              gradientColor="primary.vertical"
            >
              Team Members
            </GradientTypography>
            {!!teamNotCheckedIn && phase === 'checkIn' && (
              <CheckInButton size="small" />
            )}
          </Stack>
          <TeammatesTable teammates={teammates} />
        </Stack>
      )}
      {!!teammatesAccepted &&
        !!minTeamSize &&
        (preventMerging ||
          (continuousRegistration && isReadyOrLive(phase))) && (
          <AlertSufficientMinTeam
            teammates={teammatesAccepted}
            minTeamSize={minTeamSize}
          />
        )}
      <LockTeamCheck />
      <Stack justifyContent={'center'} alignItems={'center'}>
        <AdapexAdContainer
          baseContainerId={updatedContainerId}
          width={TEAM_MEMBERS_DIMENSIONS.width}
          height={TEAM_MEMBERS_DIMENSIONS.height}
          adjustable={TEAM_MEMBERS_DIMENSIONS.adjustable}
          borderRadius={AD_BORDER_RADIUS}
        />
      </Stack>
    </>
  );
}
