import { useState, useCallback, ReactNode, FC } from 'react';
import { memo } from '../util/memo';
import { LottieLoaderProps } from '../components/LottieLoader';
import { LoadingWrapper } from '../components/LoadingWrapper';

export function useLoadingWrapper() {
  const [isLoading, setLoading] = useState<boolean>(false);

  const loadingWhile = useCallback(
    <TFunc extends (...args: any[]) => any>(func: TFunc): TFunc => {
      return ((...args: Parameters<TFunc>): ReturnType<TFunc> => {
        setLoading(true);
        const result = func(...args);
        const isPromise = result instanceof Promise;

        if (isPromise) {
          return result.finally(() => {
            return setLoading(false);
          }) as ReturnType<TFunc>;
        }

        setLoading(false);
        return result;
      }) as TFunc;
    },
    [],
  );

  const LoadingWrapperInternalUnmemoized: FC<
    LottieLoaderProps & { children: ReactNode }
  > = ({ children, sx, ...lottieProps }) => {
    return (
      <LoadingWrapper isLoading={isLoading} sx={sx} {...lottieProps}>
        {children}
      </LoadingWrapper>
    );
  };

  const LoadingWrapperInternal = memo(LoadingWrapperInternalUnmemoized);

  return { loadingWhile, LoadingWrapper: LoadingWrapperInternal, isLoading };
}
