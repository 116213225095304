// useRenderColumns.ts
import { useCallback } from 'react';
import { ColumnLoserProps, ColumnWrapperLoser } from './ColumnWrapperLoser';
import { UseRenderColumns } from './useRenderColumns';
import { pairSubsequentMatches } from '../../../../../functions/src/util/tournaments/pairSubsequentMatches';

export const useRenderColumnsLoser: UseRenderColumns<ColumnLoserProps> = ({
  rounds,
  ColumnComponent,
  hasFinal = false,
}) => {
  const matchPairs = pairSubsequentMatches(rounds);

  return useCallback(() => {
    return matchPairs.map(({ pairs }) => {
      return (
        <ColumnWrapperLoser
          key={pairs[0][0].id}
          matchPairs={pairs}
          ColumnComponent={ColumnComponent}
          hasFinal={hasFinal}
        />
      );
    });
  }, [matchPairs, ColumnComponent, hasFinal]);
};
