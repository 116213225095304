import { useContext, createContext, ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import {
  MatchAggregated,
  Outcome,
} from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { CompetitorWithMembers } from './details/MatchDetails';
import { matchCompetitorsOf } from '../../../util/tournaments/matchCompetitorsOf';

export type MatchProps = MatchAggregated<Date> & {
  isLarge?: boolean;
  outcome?: Outcome;
  competitor1?: CompetitorWithMembers;
  competitor2?: CompetitorWithMembers;
};

export const MatchContext = createContext<
  (MatchProps & { isMatchDisabled: boolean }) | null
>(null);

export const useMatch = () => {
  const context = useContext(MatchContext);
  if (!context) {
    throw new Error('useMatch must be used within a MatchProvider');
  }
  return context;
};

export const MatchProvider = memo(function MatchProviderUnmemoized({
  children,
  isMatchDisabled,
  ...match
}: MatchProps & {
  children: ReactNode;
  isMatchDisabled: boolean;
}) {
  const [team1, team2] = matchCompetitorsOf(match);
  const { competitor: competitorTeam1, ...restTeam1 } = team1;
  const { competitor: competitorTeam2, ...restTeam2 } = team2;

  const competitor1 = useMemo(() => {
    return {
      ...restTeam1,
      ...competitorTeam1,
      members: match.team1?.members || [],
    };
  }, [competitorTeam1, match.team1?.members, restTeam1]);

  const competitor2 = useMemo(() => {
    return {
      ...restTeam2,
      ...competitorTeam2,
      members: match.team2?.members || [],
    };
  }, [competitorTeam2, match.team2?.members, restTeam2]);

  const outcome = competitor1?.outcome && competitor2?.outcome;

  const matchData = useMemo(() => {
    return {
      outcome,
      competitor1,
      competitor2,
      ...match,
      isMatchDisabled,
    };
  }, [competitor1, competitor2, isMatchDisabled, match, outcome]);

  return (
    <MatchContext.Provider value={{ ...matchData }}>
      {children}
    </MatchContext.Provider>
  );
});
