import { type ComponentType, type ReactNode } from 'react';
import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { Sponsor } from '../../../functions/src/types/firestore/Game/Competition';
import { SponsorWrapperV3 } from './SponsorWrapperV3';

export type SponsorsLayoutProps<
  TComponentType extends ComponentType<Sponsor & Record<string, unknown>>,
> = {
  sponsors: Sponsor[];
  Title?: ReactNode;
  SponsorComponent: TComponentType;
  sponsorComponentProps?: (index: number) => Record<string, unknown>;
};

export const SponsorsLayoutV3 = memo(function SponsorsLayoutV3Unmemoized<
  TComponentType extends ComponentType<Sponsor & Record<string, unknown>>,
>({
  sponsors,
  SponsorComponent,
  sponsorComponentProps,
  Title,
}: SponsorsLayoutProps<TComponentType>) {
  return (
    <Stack direction="column" gap={2}>
      {Title}
      <Stack gap={{ lg: 4, xs: 3 }} direction="row" flexWrap="wrap">
        {sponsors.map((sponsor, index) => {
          const additionalProps = sponsorComponentProps
            ? sponsorComponentProps(index)
            : {};
          const { name, imgUrl } = sponsor;
          return (
            <SponsorWrapperV3
              key={`${name}-${imgUrl}`}
              sx={{
                minWidth: '200px',
                flexBasis: '200px',
                flexGrow: 1,
              }}
            >
              {/* TODO: type errors */}
              <SponsorComponent {...sponsor} {...additionalProps} />
            </SponsorWrapperV3>
          );
        })}
      </Stack>
    </Stack>
  );
});
