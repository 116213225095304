import React, { useMemo, useCallback, useContext } from 'react';
import { memo } from '../../util/memo';
import { LiveVideoCard } from '../cards/LiveVideoCard';
import { HorizontalCarousel } from '../algolia/catalogs/HorizontalCarousel';
import { SxProps } from '@mui/material/styles';
import { SelectableRouted } from '../routing/SelectableRouted';
import { VideoContent } from '../../../functions/src/types/firestore/VideoRegistry';
import Stack from '@mui/material/Stack';
import {
  ACCORDION_TITLE_LIVESTREAM,
  AccordionBodyLivestream,
} from './AccordionBodyLivestream';
import { useSelectedVideoUrl } from '../../contexts/SelectedVideoUrlContext';
import { AccordionInfo } from '../AccordionInfo';
import { TournamentContext } from '../../contexts/docs/TournamentContext';

export type VideoContentCarouselMobileProps = {
  vods: VideoContent[];
  containerSx: SxProps;
};

export const VideoContentCarouselMobileUnmemoized = ({
  vods,
  containerSx,
}: VideoContentCarouselMobileProps) => {
  const { setSelectedVideoUrl: onSelectVideo } = useSelectedVideoUrl();

  const { title: tournamentTitle } = useContext(TournamentContext);

  const changeSelect = useCallback(
    (contentUrl: string, isSelected: boolean) => {
      if (isSelected) {
        onSelectVideo(contentUrl);
      }
    },
    [onSelectVideo],
  );

  const LiveCards = useMemo(() => {
    return vods.map(
      ({ thumbnailUrl, contentTitle, channelTitle, postedAt, contentUrl }) => {
        return {
          key: channelTitle,
          Node: (
            <SelectableRouted
              value={contentUrl}
              onChange={changeSelect}
              queryParamKey="video"
              borderRadius="10px"
            >
              <LiveVideoCard
                thumbnail={thumbnailUrl}
                title={contentTitle}
                username={channelTitle}
                postedAt={postedAt}
                videoUrl={contentUrl}
              />
            </SelectableRouted>
          ),
        };
      },
    );
  }, [changeSelect, vods]);

  return (
    <Stack
      sx={{
        ...containerSx,
      }}
    >
      <Stack sx={{ mb: -14, py: 4, width: '300px', zIndex: 2 }}>
        <AccordionInfo
          title={ACCORDION_TITLE_LIVESTREAM}
          body={<AccordionBodyLivestream tournamentTitle={tournamentTitle} />}
          openInDialog={true}
        />
      </Stack>
      <HorizontalCarousel
        Content={LiveCards}
        itemWidth={310}
        minHeight={'120px'}
      />
    </Stack>
  );
};

export const VideoContentCarouselMobile = memo(
  VideoContentCarouselMobileUnmemoized,
);
