import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { GradientIcon } from 'src/components/gradients/GradientIcon';
import { memo } from '../../../util/memo';
import { SxProps } from '@mui/material/styles';

export type BracketLoserTitleProps = {
  sx?: SxProps;
};

export const BracketLoserTitle = memo(function BracketLoserTitleUnmemoized({
  sx = {},
}: BracketLoserTitleProps) {
  return (
    <Stack direction="row" alignItems="center" sx={sx}>
      <Divider sx={{ flexGrow: 1 }} />
      <GradientTypography gradientColor="warning.vertical" variant="h5" pl={4}>
        REIGNING BRACKET
      </GradientTypography>
      <GradientIcon
        IconComponent={SwapVertIcon}
        gradientColor="warning.horizontal"
      />
      <GradientTypography gradientColor="warning.vertical" variant="h5" pr={4}>
        REDEMPTION BRACKET
      </GradientTypography>
      <Divider sx={{ flexGrow: 1 }} />
    </Stack>
  );
});
