import { memo } from '../../../util/memo';
import { WithCancelIcon } from '../../organizer/prize-pools/prize-pool-page/WithCancelIcon';
import { SponsorWrapperV3 } from '../../tournaments/SponsorWrapperV3';
import { EventImageInput } from '../generic/EventImageInput';
import { OptionalWrapper } from '../../OptionalWrapper';

export const SPONSOR_PLACEHOLDER_NAME = 'PLACEHOLDER';

export type SponsorInputProps = {
  onRemove: () => Promise<void>;
  onChange: (urlNew: string) => Promise<void>;
  fieldName: string;
  imgUrl: string;
  name: string;
};

export const SponsorInput = memo(function SponsorInputUnmemoized({
  onRemove,
  onChange,
  fieldName,
  imgUrl,
  name,
}: SponsorInputProps) {
  return (
    <SponsorWrapperV3>
      <OptionalWrapper
        doWrap={name !== SPONSOR_PLACEHOLDER_NAME}
        Wrapper={<WithCancelIcon onRemove={onRemove}></WithCancelIcon>}
      >
        <EventImageInput
          fieldName={fieldName}
          value={imgUrl}
          onChange={onChange}
          imageProperties={{ height: 166 * 0.8, width: 166 * 0.8 }}
          required={name !== SPONSOR_PLACEHOLDER_NAME}
        />
      </OptionalWrapper>
    </SponsorWrapperV3>
  );
});
