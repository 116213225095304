import { Outcome } from '../../functions/src/types/firestore/Game/Tournament/Bracket';

export function useOutcome(outcome?: Outcome, score?: number) {
  let outcomeFormatted;

  if (score || score === 0) {
    outcomeFormatted = score;
  } else if (outcome) {
    outcomeFormatted = format(outcome);
  }

  const hasOutcome = outcomeFormatted !== undefined;

  return { outcomeFormatted, hasOutcome };
}

function format(outcome: Outcome): string {
  return outcome.slice(0, 1);
}
