import { LoadingButton } from '../../buttons/LoadingButton';
import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { useRouter } from '../../../hooks/routing/useRouter';
import { useAlertDialog } from '../../../hooks/useAlertDialog';

export const TOURNAMENT_PUBLISHING_ID = 'TOURNAMENT_PUBLISHING_DIALOG';
export const TOURNAMENT_PUBLISHING_ERROR_ID =
  'TOURNAMENT_PUBLISHING_ERROR_DIALOG';
export const TOURNAMENT_PUBLISHING_ERROR_MESSAGE =
  'INCOMPLETE TOURNAMENT FIELDS';
export const TOURNAMENT_PUBLISHING_ERROR_DESCRIPTION =
  'One or more of the fields is invalid or incomplete. Please check that you’ve entered all the fields correctly on the editing page and try again. A field will be highlighted in red if it’s invalid or incomplete';

export const PublishTournamentButton = memo(
  function PublishTournamentUnmemoized() {
    const [eventDocPath] = useRouterState({ key: 'event' });
    const { push } = useRouter();
    const { open: openErrorDialog } = useAlertDialog(
      TOURNAMENT_PUBLISHING_ERROR_ID,
    );

    const publish = useCallback(async () => {
      if (!eventDocPath) {
        return;
      }
      const { publishTournament } = await import(
        '../../../firebaseCloud/tournament/organizer/publishTournament'
      );
      const pathSegments = eventDocPath.split('/');
      const tournamentId = pathSegments[pathSegments.length - 1];
      const gameId = pathSegments[pathSegments.length - 3];
      try {
        await publishTournament({
          tournamentId,
        });
        const docPath = `Game/${gameId}/Tournament/${tournamentId}`;
        await push(`/?event=${docPath}&edit-mode=primary`);
        window.location.reload();
      } catch (e) {
        openErrorDialog({
          title: TOURNAMENT_PUBLISHING_ERROR_MESSAGE,
          description: TOURNAMENT_PUBLISHING_ERROR_DESCRIPTION,
        });
      }
    }, [eventDocPath, openErrorDialog, push]);

    return (
      <Stack spacing={2}>
        <Tooltip
          arrow
          title="Once published, you will no longer be able to edit certain fields and your event will be displayed to all users."
          placement="bottom"
        >
          <div>
            <LoadingButton
              variant="contained"
              color="success"
              size="large"
              sx={{ borderRadius: '8px', minWidth: '150px', height: '42px' }}
              onClick={publish}
            >
              Publish
            </LoadingButton>
          </div>
        </Tooltip>
      </Stack>
    );
  },
);
