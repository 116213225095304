import type { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { FC, useMemo } from 'react';
import { memo } from '../../util/memo';
import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';
import EventNote from '@mui/icons-material/EventNote';
import EventAvailable from '@mui/icons-material/EventAvailable';
import LiveTv from '@mui/icons-material/LiveTv';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TournamentPanelV3 } from './TournamentPanelV3';
import { GradientTypography } from '../gradients/GradientTypography';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import BrushIcon from '@mui/icons-material/Brush';
import { PhaseSteps } from './PhaseSteps';

export type PhaseStepperProps = {
  activePhase: Tournament['phase'];
  phasesDisabled?: Tournament['phase'][];
  skipCheckIn?: boolean;
};

export type DisplayedPhase = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  label: string;
};

export type NullableDisplayedPhase = DisplayedPhase | undefined;
const UNPUBLISHED = {
  Icon: BrushIcon,
  label: 'Drafting',
};

const REVIEW = {
  Icon: FindInPageOutlinedIcon,
  label: 'review',
};

const REGISTRATION = {
  Icon: EventNote,
  label: 'Registration',
};

const CHECK_IN = {
  Icon: EventAvailable,
  label: 'Check In',
};

const LIVE = {
  Icon: LiveTv,
  label: 'Live',
};

const PAYOUT = {
  Icon: AlarmOnIcon, //MonetizationOnIcon
  label: 'Finished', //TODO: Change this back to payout after automated payouts
};

const PhaseStepperUnmemoized: FC<PhaseStepperProps> = ({
  activePhase,
  skipCheckIn,
}) => {
  const { phasesDisplayed, phaseToVisiblePhaseMap } = useMemo(() => {
    const phases = [
      UNPUBLISHED,
      REGISTRATION,
      skipCheckIn ? undefined : CHECK_IN,
      LIVE,
      PAYOUT,
    ];

    const phaseMap: Record<Tournament['phase'], NullableDisplayedPhase> = {
      unpublished: activePhase === 'unpublished' ? UNPUBLISHED : REGISTRATION,
      review: REVIEW,
      registration: REGISTRATION,
      checkIn: skipCheckIn ? undefined : CHECK_IN,
      ready: CHECK_IN,
      live: LIVE,
      finished: PAYOUT,
      payout: PAYOUT,
    };

    return {
      phasesDisplayed: phases.filter(Boolean),
      phaseToVisiblePhaseMap: phaseMap,
    };
  }, [activePhase, skipCheckIn]);

  const activeStepIndex = phasesDisplayed.indexOf(
    phaseToVisiblePhaseMap[`${activePhase}`],
  );

  const steps = useMemo(() => {
    return (
      <PhaseSteps phases={phasesDisplayed} activeIndex={activeStepIndex} />
    );
  }, [activeStepIndex, phasesDisplayed]);

  const title = useMemo(() => {
    return (
      <GradientTypography
        variant="h6"
        fontWeight={600}
        fontSize={18}
        gradientColor="primary.vertical"
        textTransform="uppercase"
      >
        Current phase
      </GradientTypography>
    );
  }, []);

  return <TournamentPanelV3 title={title} Content={steps} />;
};

export const PhaseStepper = memo(PhaseStepperUnmemoized);
