import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AvatarNext } from 'src/components/AvatarNext';
import { CompetitorOutcome } from '../../CompetitorOutcome';
import { useMatch } from '../MatchProvider';
import { type SxProps } from '@mui/material/styles';
import { memo } from '../../../../util/memo';
import { Outcome } from '../../../../../functions/src/types/firestore/Game/Tournament/Bracket';

export type MatchDetailsCompetitorProps = {
  name?: string;
  avatarUrl?: string;
  outcome?: Outcome;
  showStatus: boolean;
  sx?: SxProps;
  innerSx?: SxProps;
  typographySx?: SxProps;
};

export const MatchDetailsCompetitor = memo(
  function MatchDetailsCompetitorUnmemoized({
    name,
    avatarUrl,
    outcome,
    showStatus,
    typographySx,
    innerSx,
    sx,
  }: MatchDetailsCompetitorProps) {
    const { status } = useMatch();
    const teamResult = showStatus && outcome;
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="50%"
        sx={{
          ...sx,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            ...innerSx,
          }}
        >
          {!!name && (
            <>
              <AvatarNext src={avatarUrl} />
              <Box sx={{ ...typographySx }}>
                <Typography
                  color="text.primary"
                  sx={{ wordBreak: 'break-all' }}
                >
                  {name}
                </Typography>
              </Box>
            </>
          )}
        </Stack>
        {teamResult && (
          <CompetitorOutcome
            outcome={outcome}
            status={!!status ? status : 'inactive'}
          />
        )}
      </Stack>
    );
  },
);
