import { ChangeEvent, FC, ReactNode } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { type SxProps, useTheme } from '@mui/material/styles';
import { GradientTypography } from '../../gradients/GradientTypography';
import { GradientBorder } from '../../gradients/GradientBorder';

export type SwitchPanelAlgoliaProps = {
  // Filter?: ReactNode;
  title: string | ReactNode;
  onToggle?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked: boolean;
  sx?: SxProps;
};

const SwitchPanelAlgoliaUnmemoized: FC<SwitchPanelAlgoliaProps> = ({
  // Filter,
  title,
  onToggle,
  checked,
  sx,
}) => {
  const theme = useTheme();
  return (
    <GradientBorder borderRadius={'50px'}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          px: 4,
          background: theme.palette.background.elevationSolid[10],
          borderRadius: '50px',
          ...sx,
        }}
      >
        {typeof title === 'string' ? (
          <GradientTypography
            variant="h6"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {title}
          </GradientTypography>
        ) : (
          title
        )}
        <Switch
          color={checked ? 'primary' : 'default'}
          onChange={onToggle}
          checked={checked}
        />
      </Stack>
    </GradientBorder>
  );
};

export const SwitchPanelAlgolia = memo(SwitchPanelAlgoliaUnmemoized);
