import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { useTeamLockStatus } from '../../../hooks/registration/useTeamLockStatus';
import { useAuth } from '../../../contexts/AuthContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const LOCK_TEAM_DESCRIPTION =
  'By checking this box, I agree to fill my team with random players. My team must satisfy the minimum required size to prevent teammate reassignments. Otherwise, my teammates and I may be merged into another team.';

export function LockTeamCheck() {
  const { uid } = useAuth();
  const {
    preventMerging,
    gameId,
    foundTeam,
    id: tournamentId,
  } = useTournamentRegistration();
  const { id: teamId, isLocked = false } = foundTeam!;
  const { isTeamLocked, setIsTeamLocked } = useTeamLockStatus({
    gameId,
    tournamentId,
    teamId,
    userId: uid || undefined,
    isLockedDefault: isLocked,
  });
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pt: 4,
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              disabled={preventMerging}
              defaultChecked={!isTeamLocked && !preventMerging}
              onChange={() => {
                setIsTeamLocked((prev) => {
                  return !prev;
                });
              }}
            />
          }
          label={LOCK_TEAM_DESCRIPTION}
          sx={{
            ml: '-8px',
            mr: '8px',
            color: 'text.secondary',
            '.MuiTypography-root': {
              fontSize: '14px',
            },
          }}
        />
      </FormGroup>
    </Box>
  );
}
