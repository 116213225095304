import React, { ReactNode } from 'react';
import { memo } from '../../../util/memo';
//import { SwitchFilterChip } from '../filters/SwitchFilterChip';
import Stack from '@mui/material/Stack';
import { SearchAlgolia } from '../search/SearchAlgolia';

export type TeamsCarouselHeaderProps = {
  Title: ReactNode;
  children?: ReactNode;
};

const TeamsCarouselHeaderUnmemoized: React.FC<TeamsCarouselHeaderProps> = ({
  Title,
  children,
}) => {
  return (
    <Stack width="100%">
      <Stack sx={{ ml: 2 }}>{Title}</Stack>
      <Stack width="100%" direction="row" spacing={4} sx={{ my: 2 }}>
        {children}
        <SearchAlgolia collapsable={false} />
      </Stack>
    </Stack>
  );
};

export const TeamsCarouselHeader = memo(TeamsCarouselHeaderUnmemoized);
