import { memo } from '../../../util/memo';
import { TournamentPanelV3 } from '../TournamentPanelV3';
import { RegisteredTeamPanelHeader } from './RegisteredTeamPanelHeader';
import { RegisteredTeamEditing } from './RegisteredTeamEditing';
import { DisbandTeamButton } from './DisbandTeamButton';
import { MAX_PANEL_WIDTH } from './containers/RegistrationContainer';

const RegisteredTeamPanelUnmemoized = () => {
  return (
    <TournamentPanelV3
      wrapperSx={{ p: 4, maxWidth: MAX_PANEL_WIDTH, gap: 4 }}
      title={<RegisteredTeamPanelHeader />}
      description={<RegisteredTeamEditing />}
      Content={<DisbandTeamButton />}
    />
  );
};

export const RegisteredTeamPanel = memo(RegisteredTeamPanelUnmemoized);
