import {
  Bracket,
  MatchAggregated,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { HttpsError } from '../../../util/errors/HttpsError';
import { RoundsSecretaryIndex } from './RoundsSecretaryIndex';

/**
 * TODO: ultimately this class should have knowledge of the
 * current, previous, and next matches
 * We can then use it for more complex operations on the bracket. e.g.,
 * recursively reset matches containing a given team/winner in various ways
 */
export class BracketTeamMover<TTime> {
  public readonly roundsSecretaryIndex: RoundsSecretaryIndex<TTime>;

  public constructor(
    public bracket: Bracket<TTime>,
    public readonly matchId: string,
  ) {
    this.roundsSecretaryIndex = new RoundsSecretaryIndex<TTime>(bracket);
  }

  public get match() {
    const match = this.roundsSecretaryMatch.findMatch(this.matchId);
    if (!match) {
      throw new HttpsError(
        'not-found',
        `Could not find match`,
        `Match id: ${this.matchId}`,
      );
    }
    return match;
  }

  protected get roundsSecretaryMatch() {
    return this.roundsSecretaryIndex.secretaryOf(this.matchId).roundsSecretary;
  }

  public get nextMatch() {
    const { next } = this.match;
    if (!next || !this.roundsSecretaryNext) {
      return undefined;
    }

    return this.roundsSecretaryNext.findMatch(next);
  }

  protected get roundsSecretaryNext() {
    return this.match.next
      ? this.roundsSecretaryIndex.secretaryOf(this.match.next).roundsSecretary
      : undefined;
  }

  public get nextLoserMatch() {
    const { nextLoser } = this.match;
    if (!nextLoser || !this.roundsSecretaryNextLoser) {
      return undefined;
    }

    return this.roundsSecretaryNextLoser.findMatch(nextLoser);
  }

  protected get roundsSecretaryNextLoser() {
    return this.match.nextLoser
      ? this.roundsSecretaryIndex.secretaryOf(this.match.nextLoser)
          .roundsSecretary
      : undefined;
  }

  protected get isGrandFinal() {
    return (
      this.roundsSecretaryIndex.secretaryOf(this.matchId).bracketRoundsKeys ===
      'grandFinal'
    );
  }

  protected get isWinnerFromLoserBracket() {
    if (!this.isGrandFinal) {
      return false;
    }

    return this.loserBracketFinal?.winner?.id === this.resolveWinnerId();
  }

  public resolveWinnerId(winnerId?: string) {
    const winnerIdResolved = winnerId || this.match.winner?.id;
    if (!winnerIdResolved) {
      throw new HttpsError(
        'failed-precondition',
        'Winner must be set on the match to advance or passed in advance as an argument.',
      );
    }
    return winnerIdResolved;
  }

  protected get loserBracketFinal() {
    return this.bracket?.bracketLoser?.[this.bracket?.bracketLoser?.length - 1]
      ?.matches?.[0];
  }

  public get loserTeam() {
    const { winner, team1, team2 } = this.match;
    if (!winner) {
      throw new HttpsError(
        'failed-precondition',
        'Winner must be set on the match to find the loser team.',
      );
    }
    return winner.id === team1?.id ? team2 : team1;
  }

  public loserPrevious(
    key: 'previous1' | 'previous2',
  ): MatchAggregated<TTime> | undefined {
    const loserPreviousMatch = this.nextLoserMatch?.[`${key}`];
    if (!loserPreviousMatch) {
      return undefined;
    }
    return this.matchOfId(loserPreviousMatch);
  }

  private matchOfId(matchId: string) {
    return this.roundsSecretaryIndex
      .secretaryOf(matchId)
      .roundsSecretary.findMatch(matchId);
  }
}