import { useMemo } from 'react';
import { memo } from '../../../../util/memo';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MatchDetailsTeams } from './MatchDetailsTeams';
import { Member } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { CompetitorOutcomeProps } from '../../CompetitorOutcome';
import { Competitor } from '../MatchCompetitor';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { useTheme } from '@mui/material/styles';
import { MatchLiveBadge } from '../MatchLiveBadge';
import { generateRowData } from 'src/util/bracket/generateRowData';
import { FormattedPayoutSummary } from '../../FormattedPayoutSummary';
import { useRouterState } from '../../../../hooks/routing/useRouterState';
import { SessionDetails } from './SessionDetails';
import { TabPane, TabPanes } from '../../../routing/TabPanes';
import { Round } from '../../../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { useMatch } from '../MatchProvider';

export const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

export type MatchCompetitor = Competitor &
  Pick<CompetitorOutcomeProps, 'outcome' | 'score'>;

export type CompetitorWithMembers = MatchCompetitor & {
  members: Member[];
};

export type MatchDetailsProps = { rounds: Round<Date>[] };

export const MatchDetails = memo(function MatchDetailsUnmemoized({
  rounds,
}: MatchDetailsProps) {
  const { outcome, startTime, team1, team2, id, team1Scores = [] } = useMatch();

  const { reward, rewardSecured } = useMemo(() => {
    const round = rounds.find((round) => {
      return round.matches?.find((match) => {
        return match.id === id;
      });
    });
    const rewardRound = round?.payout;
    const roundPreviousId = round?.previous;
    const roundPrevious = rounds.find((round) => {
      return round.id === roundPreviousId;
    });
    const rewardSecuredNext = roundPrevious?.payout;
    return { reward: rewardRound, rewardSecured: rewardSecuredNext };
  }, [id, rounds]);

  const tabPanesState = useRouterState({
    key: 'sessionNumber',
    defaultValue: `0`,
  });

  const gameSessions = useMemo(() => {
    return !!team1Scores.length
      ? team1Scores.map((_, index) => {
          return `Session ${index + 1}`;
        })
      : ['Session 1'];
  }, [team1Scores]);

  const sessionTabPanes: TabPane<string>[] = useMemo(() => {
    return gameSessions.map((session, index) => {
      return {
        value: `${index}`,
        component: <SessionDetails />,
        customization: { label: session },
      };
    });
  }, [gameSessions]);

  const theme = useTheme();
  const format = useDateFormatter({ dateFormat: DATE_FORMAT, separator: ', ' });
  const dateFormatted = startTime ? format(startTime) : undefined;
  const rowsData = generateRowData(team1, team2);

  return (
    <MatchLiveBadge>
      <Stack direction="column" p={4} width="100%">
        <TabPanes
          tabPanes={sessionTabPanes}
          state={tabPanesState}
          variant="chip"
        />
        {!!rowsData.length && (
          <MatchDetailsTeams
            rowsData={rowsData}
            sx={{
              borderRadius: reward || rewardSecured ? 'none' : '10px',
              background: theme.palette.background.elevation[10],
            }}
          />
        )}
        <Stack direction="column" alignItems="center" width="100%">
          {!!rewardSecured?.length && (
            <>
              <FormattedPayoutSummary payoutSingle={rewardSecured} />
              {' Guaranteed.'}
            </>
          )}
          {!!reward?.length && (
            <>
              <span
                style={{
                  color: theme.palette.text.secondary,
                }}
              >
                {`Play${!!outcome ? 'ed' : 'ing'} for `}
              </span>
              <FormattedPayoutSummary payoutSingle={reward} />
            </>
          )}
          <Typography variant="caption" color="text.secondary">
            {dateFormatted}
          </Typography>
        </Stack>
      </Stack>
    </MatchLiveBadge>
  );
});
