import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { GradientTypography } from '../../gradients/GradientTypography';
import { GradientBorder } from 'src/components/gradients/GradientBorder';
import { useNumberFormatter } from 'src/hooks/useNumberFormatter';
import { FeaturedCompetitor } from 'src/components/leaderboards/FeaturedCompetitor';
import { Link } from 'src/components/Link';
import { useTheme } from '@mui/material/styles';
import { useMobile } from 'src/hooks/useMobile';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { OptionalWrapper } from 'src/components/OptionalWrapper';
import { TeamDisplayTooltip } from './TeamDisplayTooltip';
import { ResultRanked } from '../../../../functions/src/types/firestore/Game/Tournament/Heat/Result';
import { formatTimeTaken } from './Score';

export type HeatTop3Props = {
  top3Results: Omit<ResultRanked, 'userIds'>[];
  units?: string;
};

export const HeatTop3 = memo(function HeatTop3Unmemoized({
  top3Results,
  units = 'pts',
}: HeatTop3Props) {
  const theme = useTheme();
  const isMobile = useMobile();
  const formatterStandard = useNumberFormatter({
    notation: 'standard',
  });

  function format(score = 0): string {
    if (units === 'time') {
      return formatTimeTaken(score);
    }
    return `${formatterStandard.format(score)} ${units?.toUpperCase()}`;
  }
  const screenSizeDrop = useMediaQuery(theme.breakpoints.down(1650));

  return (
    <GradientBorder
      disableBorder
      borderRadius={'10px'}
      gradientBackground={`linear-gradient(${theme.palette.background.elevation[7]}, 
      ${theme.palette.background.elevation[7]})`}
    >
      <GradientTypography
        gradientColor="primary.vertical"
        variant="h6"
        sx={{
          mt: 2,
          ml: 3,
        }}
      >
        TOP {top3Results.length}
      </GradientTypography>

      <Stack
        flexDirection="row"
        justifyContent={'center'}
        alignItems="center"
        flexWrap={isMobile || screenSizeDrop ? 'wrap' : 'inherit'}
        sx={{
          px: 3,
          pb: 3,
        }}
      >
        {top3Results?.map(
          ({ imgUrl, name, payout, scores, rank, userIdCaptain, members }) => {
            // TODO: replace these Links with profile previous when implemented
            const captainUrl = `/profile/${userIdCaptain}`;
            const isSolo = !members || members.length === 1;
            return (
              <OptionalWrapper
                key={`${userIdCaptain}-${rank}`}
                doWrap={isSolo && !!userIdCaptain && userIdCaptain !== ''}
                Wrapper={
                  <Link
                    key={`${name}-${rank}`}
                    href={captainUrl}
                    style={{ textDecoration: 'none' }}
                  >
                    <></>
                  </Link>
                }
              >
                <Box>
                  <OptionalWrapper
                    doWrap={!isSolo}
                    Wrapper={
                      <TeamDisplayTooltip
                        teamDisplayProps={{
                          avatarUrl: imgUrl!,
                          members: members!,
                          name,
                        }}
                      >
                        <></>
                      </TeamDisplayTooltip>
                    }
                  >
                    <FeaturedCompetitor
                      imgUrl={isSolo ? members?.[0].imgUrl : imgUrl}
                      name={name}
                      rank={rank}
                      payout={payout}
                      isSolo={isSolo}
                      score={format(scores?.[0])}
                    />
                  </OptionalWrapper>
                </Box>
              </OptionalWrapper>
            );
          },
        )}
      </Stack>
    </GradientBorder>
  );
});
