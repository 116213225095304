import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { memo } from '../util/memo';
import { TournamentContext } from './docs/TournamentContext';
import { usePlatformVideos } from '../hooks/usePlatformVideos';
import { VideoContent } from '../../functions/src/types/firestore/VideoRegistry';

export type SelectedVideoUrlContextType = {
  videos: VideoContent[];
  selectedVideoUrl?: string;
  setSelectedVideoUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const SelectedVideoUrlContext = createContext<
  SelectedVideoUrlContextType | undefined
>(undefined);

export const useSelectedVideoUrl = () => {
  const context = useContext(SelectedVideoUrlContext);
  if (!context) {
    throw new Error(
      'useSelectedVideoUrl must be used within a SelectedVideoUrlProvider',
    );
  }
  return context;
};

export type SelectedVideoUrlProviderProps = {
  children: React.ReactNode;
};

const SelectedVideoUrlProviderUnmemoized: React.FC<
  SelectedVideoUrlProviderProps
> = ({ children }) => {
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string | undefined>(
    undefined,
  );
  const tournament = useContext(TournamentContext);
  const { id: tournamentId, gameId } = tournament;

  const path = `Game/${gameId}/Tournament/${tournamentId}`;
  const videos = usePlatformVideos(path);

  useEffect(() => {
    if (!!videos?.length) {
      setSelectedVideoUrl(videos[0].contentUrl);
    }
  }, [videos]);

  const memoizedValue = useMemo(() => {
    return {
      selectedVideoUrl,
      setSelectedVideoUrl,
      videos,
    };
  }, [selectedVideoUrl, videos]);

  return (
    <SelectedVideoUrlContext.Provider value={memoizedValue}>
      {children}
    </SelectedVideoUrlContext.Provider>
  );
};

export const SelectedVideoUrlProvider = memo(
  SelectedVideoUrlProviderUnmemoized,
);
