import { FC } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/system/Stack';
import { SelectionEvent, SelectionEventProps } from './SelectionEvent';
import {
  SelectableEventsCalendarProps,
  SelectableEventsCalendar,
} from './SelectableEventsCalendar';

export type ExpandableEventsCalendarProps = Pick<
  SelectionEventProps,
  'autoScroll'
> &
  Omit<SelectableEventsCalendarProps, 'height' | 'Extension'> & {
    calendarHeight?: string;
    selectionMinHeight?: string;
  };

const ExpandableEventsCalendarUnmemoized: FC<ExpandableEventsCalendarProps> = ({
  autoScroll,
  calendarHeight,
  selectionMinHeight,
  ...props
}) => {
  return (
    <Stack spacing={4}>
      <SelectableEventsCalendar {...props} height={calendarHeight} />
      <SelectionEvent autoScroll={autoScroll} minHeight={selectionMinHeight} />
    </Stack>
  );
};

export const ExpandableEventsCalendar = memo(
  ExpandableEventsCalendarUnmemoized,
);
