import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { GradientBorder } from 'src/components/gradients/GradientBorder';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { TypographyParagraphed } from 'src/components/TypographyParagraphed';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TournamentDetail } from 'functions/src/types/firestore/Game/Tournament';
import { useState } from 'react';
import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';

export type TournamentDetailsPanelProps = {
  tournamentDetails: TournamentDetail[];
};

export const LIVE_STREAMS_DEFAULT = `You will be able to watch live streams by competitors on the tournament page once the tournament begins. If you are a streamer DM @blumint_ on twitter and a BluMint team member will embed your twitch stream onto the video banner frame of https://blumint.com/game/metaops! The BluMint team would like to honor not just great games but also great gamers :)`;

export const TournamentDetailsPanel = memo(
  function TournamentDetailsPanelUnmemoized(
    props: TournamentDetailsPanelProps,
  ) {
    const { tournamentDetails } = props;

    const theme = useTheme();

    const [isLastExpanded, setIsLastExpanded] = useState(false);
    return (
      <GradientBorder
        sx={{ borderRadius: '10px', width: '100%' }}
        disableBorder
      >
        {tournamentDetails &&
          tournamentDetails.map(({ id, title, body }, index) => {
            return (
              <Accordion
                defaultExpanded={index === 0}
                disableGutters
                key={id || `${title}-${body}`}
                variant="outlined"
                onChange={(_, expanded) => {
                  if (index === tournamentDetails.length - 1) {
                    setIsLastExpanded(expanded);
                  }
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: theme.panels[1],
                    borderRadius:
                      index === 0
                        ? '10px 10px 0px 0px'
                        : index === tournamentDetails.length - 1 &&
                          !isLastExpanded
                        ? '0px 0px 10px 10px'
                        : '0px 0px 0px 0px',
                    borderBottom: 'none',
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <GradientTypography
                    gradientColor="primary.vertical"
                    variant="h6"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    {title}
                  </GradientTypography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    border: 'none',
                    backgroundColor: theme.panels[2],
                    borderRadius:
                      index === tournamentDetails.length - 1
                        ? '10px 10px 10px 10px'
                        : undefined,
                  }}
                >
                  <TypographyParagraphed text={body} sx={{ py: 2 }} />
                </AccordionDetails>
              </Accordion>
            );
          })}
      </GradientBorder>
    );
  },
);
