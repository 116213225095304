import { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useMatch } from '../../components/tournaments/match/MatchProvider';
import { useMatchPermissions } from './useMatchPermissions';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { uuidv4Base62 } from '../../../functions/src/util/uuidv4Base62';
import { useAlertDialog } from '../useAlertDialog';

export const UPLOAD_ERROR_DIALOG_ID = 'UPLOAD_ERROR_DIALOG_ID';
export const UPLOAD_ERROR_DIALOG_TITLE = 'Upload Was Unsuccessful';
export const UPLOAD_ERROR_DIALOG_DESCRIPTION =
  'An error occured while uploading your screenshot. Please kindly try again. If the issue persists, please contact our support team for help.';

export const useSetMatchScreenshot = (sessionNumber?: number) => {
  const { uid } = useAuth();
  const { id: tournamentId } = useContext(TournamentContext);
  const { id: matchId } = useMatch();
  const { canEditScreenshot } = useMatchPermissions();
  const { open: openUploadError } = useAlertDialog(UPLOAD_ERROR_DIALOG_ID);

  const hasPermissions = useMemo(() => {
    return uid && canEditScreenshot && sessionNumber !== undefined;
  }, [uid, canEditScreenshot, sessionNumber]);

  const warnPermissions = () => {
    console.warn('useSetMatchScreenshot invoked without all parameters valid.');
  };

  const uploadToStorage = useCallback(
    async (file: File) => {
      if (!hasPermissions) {
        warnPermissions();
        return undefined;
      }
      try {
        const { uploadImage } = await import('src/util/uploadImage');
        const newScreenshotUrl = await uploadImage({
          file,
          storagePath: `Ugc/${uid}/${matchId}/${sessionNumber}/${uuidv4Base62()}`,
        });
        return newScreenshotUrl;
      } catch (error) {
        openUploadError({
          title: UPLOAD_ERROR_DIALOG_TITLE,
          description: UPLOAD_ERROR_DIALOG_DESCRIPTION,
        });
        console.error('Screenshot upload failed', error);
        return undefined;
      }
    },
    [hasPermissions, matchId, openUploadError, sessionNumber, uid],
  );

  const updateScreenshotFirestore = useCallback(
    async (url?: string, previousUrl?: string) => {
      if (!hasPermissions || !url) {
        warnPermissions();
        return;
      }
      const { updateMatchScreenshot } = await import(
        '../../firebaseCloud/tournament/updateMatchScreenshot'
      );
      await updateMatchScreenshot({
        matchId,
        tournamentId,
        sessionIndex: sessionNumber,
        screenshotUrl: url,
        previousUrl,
      });
    },
    [hasPermissions, matchId, sessionNumber, tournamentId],
  );

  const set = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, previousUrl?: string) => {
      const { currentTarget } = event;
      if (!currentTarget || !currentTarget.files?.length) return;

      const file = currentTarget.files[0];

      try {
        const newUrl = await uploadToStorage(file);
        await updateScreenshotFirestore(newUrl, previousUrl);
      } catch (error) {
        console.error('Screenshot upload failed', error);
      }
      currentTarget.value = '';
    },
    [updateScreenshotFirestore, uploadToStorage],
  );

  const deleteScreenshot = useCallback(
    async (url: string) => {
      await updateScreenshotFirestore(url);
    },
    [updateScreenshotFirestore],
  );

  const addScreenshot = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await set(event);
    },
    [set],
  );

  const replaceScreenshot = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, previousUrl: string) => {
      await set(event, previousUrl);
    },
    [set],
  );

  return {
    addScreenshot,
    deleteScreenshot,
    replaceScreenshot,
  };
};
