import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { memo } from '../util/memo';
import { useRouterState } from '../hooks/routing/useRouterState';

export type ActiveMatchRouterContextProps = {
  matchId?: string;
  sessionNumber?: number;
  close: () => void;
  open: (matchId: string, sessionNumber?: number) => void;
  switchSession: (sessionNumber: number) => void;
};

export const ActiveMatchRouterContext = createContext<
  ActiveMatchRouterContextProps | undefined
>(undefined);

export type ActiveMatchRouterProps = {
  children: ReactNode;
};

export const useActiveMatchRouter = () => {
  const context = useContext(ActiveMatchRouterContext);
  if (!context) {
    throw new Error(
      'useActiveMatchRouter must be used within an ActiveMatchRouterProvider',
    );
  }
  return context;
};

const ActiveMatchRouterProviderUnmemoized = ({
  children,
}: ActiveMatchRouterProps) => {
  const [matchId, setMatchId] = useRouterState({
    key: 'match-dialog',
    location: 'queryParam',
  });

  const [sessionNumberUnparsed, setSessionNumberUnparsed] = useRouterState({
    key: 'session-dialog',
    location: 'queryParam',
    defaultValue: '0',
  });
  const sessionNumber = useMemo(() => {
    return parseInt(sessionNumberUnparsed);
  }, [sessionNumberUnparsed]);

  const open = useCallback(
    // eslint-disable-next-line no-shadow
    (matchId: string, sessionNumber?: number) => {
      setMatchId(matchId);
      setSessionNumberUnparsed(sessionNumber?.toString() ?? '0');
    },
    [setMatchId, setSessionNumberUnparsed],
  );

  const close = useCallback(() => {
    setMatchId(undefined);
    setSessionNumberUnparsed(undefined);
  }, [setMatchId, setSessionNumberUnparsed]);

  const switchSession = useCallback(
    // eslint-disable-next-line no-shadow
    (sessionNumber: number) => {
      setSessionNumberUnparsed(sessionNumber.toString());
    },
    [setSessionNumberUnparsed],
  );

  const memoizedValue = useMemo(() => {
    return {
      matchId,
      sessionNumber,
      open,
      close,
      switchSession,
    };
  }, [close, matchId, open, sessionNumber, switchSession]);

  return (
    <ActiveMatchRouterContext.Provider value={memoizedValue}>
      {children}
    </ActiveMatchRouterContext.Provider>
  );
};

export const ActiveMatchRouterProvider = memo(
  ActiveMatchRouterProviderUnmemoized,
);
