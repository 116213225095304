import { useContext, useMemo } from 'react';
import { TournamentContext } from '../../contexts/docs/TournamentContext';

export const useCountdownDisplay = () => {
  const { phase, skipCheckIn, date, checkInTime, readyTime, endDate } =
    useContext(TournamentContext);
  return useMemo(() => {
    switch (phase) {
      case 'unpublished':
        return skipCheckIn
          ? {
              title: 'Starts In:',
              date,
            }
          : {
              title: 'Check In Starts:',
              date: checkInTime,
            };
      case 'review':
        return {
          title: 'Starts In:',
          date,
        };
      case 'registration':
        return skipCheckIn
          ? {
              title: 'Starts In:',
              date,
            }
          : {
              title: 'Check In Starts:',
              date: checkInTime,
            };
      case 'checkIn':
        return {
          title: 'Check In By:',
          date: readyTime,
        };
      case 'ready':
        return {
          title: 'Starts In:',
          date,
        };
      case 'live':
        return {
          title: 'Live now! Ends In:',
          date: endDate,
        };
      case 'finished':
        return {
          title: 'Finished!',
          date: new Date(Date.now()),
        };
      default:
        return {
          title: 'Time Remaining:',
          date: new Date(Date.now()),
        };
    }
  }, [phase, date, skipCheckIn, checkInTime, readyTime, endDate]);
};
