import { useCallback } from 'react';

export type MatchActionsParams = {
  gameId: string;
  matchId: string;
  tournamentId: string;
};

export const useMatchActions = ({
  gameId,
  matchId,
  tournamentId,
}: MatchActionsParams) => {
  const revertCurrentMatch = useCallback(async () => {
    const { revertResult } = await import(
      '../firebaseCloud/tournament/revertResult'
    );
    await revertResult({
      gameId,
      tournamentId,
      matchId,
    });
  }, [gameId, matchId, tournamentId]);
  const recreateMatch = useCallback(async () => {
    const { recreateExternalMatch } = await import(
      '../firebaseCloud/tournament/automation/recreateExternalMatch'
    );
    await recreateExternalMatch({ gameId, matchId, tournamentId });
  }, [gameId, matchId, tournamentId]);

  const declareWinner = async (
    bestOf: number,
    winnerId: string,
    scores: number[][],
  ) => {
    const { finishMatch } = await import(
      '../firebaseCloud/tournament/finishMatch'
    );
    await finishMatch({
      gameId,
      matchId,
      tournamentId,
      winnerId,
      scores,
      bestOf,
    });
    return 'win';
  };

  const delayMatch = async () => {
    const { setMatchStatus } = await import(
      '../firebaseCloud/tournament/setMatchStatus'
    );
    await setMatchStatus({
      gameId,
      matchId,
      status: 'delayed',
      tournamentId,
    });
  };

  const cancelMatch = async () => {
    console.log('would cancel here...');
  };

  return {
    declareWinner,
    delayMatch,
    cancelMatch,
    recreateMatch,
    revertCurrentMatch,
  };
};
