import { LiveBadge } from 'src/components/LiveBadge';
import { useMatch } from './MatchProvider';
import { OptionalWrapper } from 'src/components/OptionalWrapper';
import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { Link } from '../../Link';

export type MatchLiveBadgeProps = { children: ReactNode };

export const MatchLiveBadgeUnmemoized: React.FC<MatchLiveBadgeProps> = ({
  children,
}) => {
  const { streamUrl } = useMatch();

  return (
    <OptionalWrapper
      doWrap={!!streamUrl}
      Wrapper={
        <Link href={`TODO/${streamUrl}`}>
          <LiveBadge
            sx={{
              top: -10,
              left: 'calc(50% - 22.5px)',
            }}
          />
        </Link>
      }
    >
      {children}
    </OptionalWrapper>
  );
};

export const MatchLiveBadge = memo(MatchLiveBadgeUnmemoized);
