import { memo } from '../../../util/memo';
import { toImageKit } from '../../../../functions/src/util/imageKit/toImageKit';
import Stack from '@mui/material/Stack';
import { useSelectedVideoUrl } from '../../../contexts/SelectedVideoUrlContext';
import { useEmbedVideoUrl } from '../../../hooks/useEmbedVideoUrl';

const VideoFrameUnmemoized: React.FC = () => {
  const { selectedVideoUrl: videoUrl } = useSelectedVideoUrl();

  const embeddedVideo = useEmbedVideoUrl(videoUrl);

  const optimizedVideo = !!embeddedVideo
    ? toImageKit(embeddedVideo)
    : undefined;

  return (
    <Stack
      sx={{
        position: 'relative',
        maxWidth: '1280px',
        maxHeight: '720px',
        margin: 'auto',
        width: '100%',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          display: 'block',
          paddingTop: '56.25%',
        },
      }}
    >
      <iframe
        src={optimizedVideo}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '10px',
        }}
      />
    </Stack>
  );
};

export const VideoFrame = memo(VideoFrameUnmemoized);
