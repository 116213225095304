import { Timestamp } from '../../config/firebaseAdmin';
import { Team } from '../../types/firestore/Game/Tournament/Guestlist';

export const membersOfMatch = <TTime = Timestamp>(teams?: Team<TTime>[]) => {
  if (!teams?.length) {
    return;
  }
  return teams.flatMap(({ userIds }) => {
    return userIds;
  });
};
