import { memo } from '../util/memo';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { OptionalWrapper } from './OptionalWrapper';
import Tooltip from '@mui/material/Tooltip';
import { truncateIfTooLong } from 'src/util/truncate';

export type TypographyTruncatedProps = {
  textMaxLength?: number;
  text?: string;
} & TypographyProps;

export const TypographyTruncated = memo(function TypographyTruncatedUnmemoized({
  textMaxLength = 20,
  text = '',
  ...rest
}: TypographyTruncatedProps) {
  return (
    <OptionalWrapper
      doWrap={text.length > textMaxLength}
      Wrapper={
        <Tooltip title={text} arrow disableInteractive>
          <></>
        </Tooltip>
      }
    >
      <Typography {...rest}>
        {truncateIfTooLong(text, textMaxLength)}
      </Typography>
    </OptionalWrapper>
  );
});
