import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { truncateIfTooLong } from 'src/util/truncate';
import { FC, MutableRefObject, RefObject } from 'react';
import { memo } from '../../util/memo';
import { useMatch } from '../tournaments/match/MatchProvider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export type MatchScorerProps = {
  teamNames: string[];
  teamRefs?: MutableRefObject<RefObject<HTMLInputElement>[]>;
  setGameScore?: () => void;
  onScoreChange: (teamIndex: number, gameIndex: number, score: number) => void;
  disabled?: boolean;
};

const MatchScorerUnmemoized: FC<MatchScorerProps> = ({
  teamNames,
  teamRefs,
  setGameScore,
  onScoreChange,
  disabled = false,
}) => {
  const { team1Scores, team2Scores } = useMatch();
  const scores = [team1Scores, team2Scores];
  const currentGameIndex = team1Scores?.length || 0;
  return (
    <>
      <Stack direction="column" spacing={2}>
        <Typography color="text.primary">
          Enter the game scores below.
        </Typography>
        {Array.from({
          length: (team1Scores?.length ?? 0) + 1,
        }).map((_, gameIndex) => {
          return (
            <Stack key={gameIndex} spacing={2}>
              <Typography>Game {gameIndex + 1}</Typography>
              <Stack direction="row" spacing={2}>
                {teamNames.map((teamName, teamIndex) => {
                  return (
                    <TextField
                      key={teamName}
                      type="number"
                      inputRef={teamRefs?.current?.[Number(teamIndex)] || null}
                      label={`${truncateIfTooLong(teamName)} Game ${
                        gameIndex + 1
                      } Score`}
                      defaultValue={
                        scores[Number(teamIndex)]?.[Number(gameIndex)] || 0
                      }
                      sx={{ maxWidth: '140px' }}
                      InputLabelProps={{ shrink: true }}
                      disabled={gameIndex < currentGameIndex}
                      onChange={(e) => {
                        const newScore = parseInt(e.target.value);
                        onScoreChange(teamIndex, gameIndex, newScore);
                      }}
                    />
                  );
                })}
                {gameIndex === currentGameIndex && (
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={setGameScore}
                    disabled={disabled}
                  >
                    Confirm Score
                  </Button>
                )}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};
export const MatchScorer = memo(MatchScorerUnmemoized);
