import { useMemo } from 'react';
import { useMatch } from '../../components/tournaments/match/MatchProvider';
import { Outcome } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';

export const useMatchSession = (sessionNumber = 0) => {
  const {
    team1Scores = [],
    team2Scores = [],
    screenshots = [],
    competitor1,
    competitor2,
  } = useMatch();

  const sessionInfo = useMemo(() => {
    return {
      team1Score: team1Scores?.[Number(sessionNumber)],
      team2Scores: team2Scores?.[Number(sessionNumber)],
      screenshots: screenshots?.[Number(sessionNumber)],
    };
  }, [team1Scores, sessionNumber, team2Scores, screenshots]);

  const outcome: { team1?: Outcome; team2?: Outcome } = useMemo(() => {
    if (!team2Scores?.length || !team1Scores?.length) {
      return { team1: 'bypass', team2: 'bypass' };
    }
    if (
      team1Scores[Number(sessionNumber)] > team2Scores[Number(sessionNumber)]
    ) {
      return { team1: 'win', team2: 'loss' };
    }
    return { team1: 'loss', team2: 'win' };
  }, [sessionNumber, team1Scores, team2Scores]);

  const competitor1Outcomed = useMemo(() => {
    return { ...competitor1, outcome: outcome.team1 };
  }, [competitor1, outcome.team1]);

  const competitor2Outcomed = useMemo(() => {
    return { ...competitor2, outcome: outcome.team2 };
  }, [competitor2, outcome.team2]);

  return { sessionInfo, competitor1Outcomed, competitor2Outcomed };
};
