import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { RegistrationOptionBase } from '../../../../functions/src/types/firestore/Game/Tournament';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { PostambleAlert } from './PostambleAlert';

export type FieldContainerProps = Pick<
  RegistrationOptionBase,
  'preamble' | 'postambleText' | 'postambleLink'
> & {
  children: ReactNode;
};

export const FieldContainer = memo(function FieldContainerUnmemoized({
  preamble,
  postambleLink,
  postambleText,
  children,
}: FieldContainerProps) {
  return (
    <Stack spacing={2} alignItems={'center'}>
      <Typography variant="h6">{preamble}</Typography>
      {children}
      {postambleText && (
        <PostambleAlert text={postambleText} link={postambleLink} />
      )}
    </Stack>
  );
});
