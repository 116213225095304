import { Required } from 'utility-types';
//import { log } from '../../../util/log';
import {
  Round,
  Cohort,
} from '../../../types/firestore/Game/Tournament/Bracket';
import { DEFAULT_COHORT_SIZE } from '../bracket/EliminationPairer';
import { SingleElimination } from '../bracket/SingleElimination';
import { CohortFactory } from './CohortFactory';
import { DoubleElimination } from '../bracket/DoubleElimination';
import { Timestamp } from 'firebase-admin/firestore';

type HeatChunkerOptions = {
  isDoubleElimination: boolean;
};

const DEFAULT_OPTIONS: HeatChunkerOptions = {
  isDoubleElimination: false,
};
//@log
export class HeatChunker<T = Timestamp> {
  public constructor(
    private readonly heatRounds: Round<T>[], // Should be of length (Math.log2(cohortSize) + 1)
    private readonly cohortFactory = new CohortFactory<T>(),
    private readonly cohortSize: number = DEFAULT_COHORT_SIZE,
    private readonly options = DEFAULT_OPTIONS,
  ) {}

  public get cohorts(): Cohort<T>[] {
    return [...Array(this.cohortCount).keys()].map((i) => {
      const rounds: Required<Round<T>, 'matches'>[] = this.heatRounds.map(
        (round, j) => {
          const indexFixed = !!this.options.isDoubleElimination
            ? Math.floor(j / 2)
            : j;
          const roundSize = this.cohortSize / 2 ** indexFixed;
          const matchesWindowIndex = Math.ceil(
            roundSize / (!!this.options.isDoubleElimination ? 2 : 1),
          );
          const matchesNew =
            round.matches?.slice(
              i * matchesWindowIndex,
              (i + 1) * matchesWindowIndex,
            ) || [];
          return {
            ...round,
            matches: matchesNew,
            maxMatchCount: matchesNew.length,
            finishedMatchCount: Math.min(
              round.finishedMatchCount,
              matchesNew.length,
            ),
          };
        },
      );
      if (!!this.options.isDoubleElimination) {
        DoubleElimination.tieRounds(rounds);
      } else {
        SingleElimination.tieRounds(rounds);
      }

      return this.cohortFactory.build(rounds);
    });
  }

  private get cohortCount(): number {
    const initialRound = this.heatRounds[0];
    return (
      Math.floor(
        initialRound.maxMatchCount /
          (this.cohortSize / (!!this.options.isDoubleElimination ? 2 : 1)),
      ) || 1
    );
  }
}
