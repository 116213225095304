import { memo } from '../../util/memo';
import { GradientTypography } from '../gradients/GradientTypography';
import { Status } from '../Status';
import {
  MatchAggregated,
  Outcome,
} from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { useOutcome } from 'src/hooks/useOutcome';

export type CompetitorOutcomeProps = {
  status: MatchAggregated['status'];
  outcome?: Outcome;
  score?: number;
};

export const CompetitorOutcome = memo(function CompetitorOutcomeUnmemoized(
  props: CompetitorOutcomeProps,
) {
  const { outcome, status, score } = props;

  const { outcomeFormatted, hasOutcome } = useOutcome(outcome, score);

  if (hasOutcome) {
    return (
      <GradientTypography
        variant="h6"
        gradientColor={
          outcome === 'win' || outcome === 'bypass'
            ? 'secondary.horizontal'
            : outcome === 'loss'
            ? 'error.horizontal'
            : 'warning.main'
        }
        sx={{
          textTransform: 'uppercase',
        }}
      >
        {outcomeFormatted}
      </GradientTypography>
    );
  }

  return (
    <Status
      kind="Icon"
      statusEnum={status === 'active' ? 'online' : 'away'}
      sx={{ width: 16, height: 16 }}
    />
  );
});
