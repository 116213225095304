import { useState, useCallback, useMemo, Fragment } from 'react';
import { memo } from '../../../util/memo';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import Box from '@mui/material/Box';
import { RelationshipChip } from '../friend/RelationshipChip';
import {
  FriendListItemLayout,
  FriendListItemLayoutProps,
} from '../friend/FriendListItemLayout';
import { useLoadingWrapper } from '../../../hooks/useLoadingWrapper';
import Tooltip from '@mui/material/Tooltip';
import { OptionalWrapper } from '../../OptionalWrapper';
import { phaseEnded } from '../../../../functions/src/util/tournaments/phaseEnded';

export type AddTeammateListItemProps = Pick<
  FriendListItemLayoutProps,
  'imgUrl' | 'username' | 'status'
> & { id: string };

const AddTeammateListItemUnmemoized: React.FC<AddTeammateListItemProps> = ({
  imgUrl,
  username,
  id: candidateId,
  status,
}) => {
  const {
    isAcceptedOrPendingMyTeam,
    isAcceptedAnyTeam,
    isAcceptedMyTeam,
    phase,
  } = useTournamentRegistration();

  const [isAdded, setIsAdded] = useState(
    isAcceptedOrPendingMyTeam(candidateId),
  );

  const isAcceptedAnotherTeam = useMemo(() => {
    return isAcceptedAnyTeam(candidateId) && !isAcceptedMyTeam(candidateId);
  }, [isAcceptedAnyTeam, isAcceptedMyTeam, candidateId]);

  const { invite, uninvite } = useRegistrationFunctions();

  const { loadingWhile, LoadingWrapper } = useLoadingWrapper();

  const addTeammate = useCallback(async () => {
    await invite(candidateId);
    setIsAdded(true);
  }, [candidateId, invite]);

  const removeTeammate = useCallback(async () => {
    await uninvite(candidateId);
    setIsAdded(false);
  }, [candidateId, uninvite]);

  const performAction = useMemo(() => {
    return isAcceptedAnotherTeam
      ? undefined
      : loadingWhile(isAdded ? removeTeammate : addTeammate);
  }, [
    addTeammate,
    isAcceptedAnotherTeam,
    isAdded,
    loadingWhile,
    removeTeammate,
  ]);

  const label = useMemo(() => {
    return (
      <LoadingWrapper
        sx={{
          maxHeight: '32px',
          maxWidth: '32px',
        }}
      >
        {isAdded ? 'REMOVE' : 'ADD'}
      </LoadingWrapper>
    );
  }, [LoadingWrapper, isAdded]);

  const button = useMemo(() => {
    return (
      <OptionalWrapper
        doWrap={isAcceptedAnotherTeam}
        Wrapper={
          <Tooltip title="User is already on another team.">
            <></>
          </Tooltip>
        }
      >
        <Box onClick={performAction}>
          <RelationshipChip disabled={isAcceptedAnotherTeam} label={label} />
        </Box>
      </OptionalWrapper>
    );
  }, [isAcceptedAnotherTeam, label, performAction]);

  return (
    <FriendListItemLayout
      imgUrl={imgUrl}
      username={username}
      status={status}
      userId={candidateId}
    >
      {!phaseEnded(phase) && <Fragment>{button}</Fragment>}
    </FriendListItemLayout>
  );
};

export const AddTeammateListItem = memo(AddTeammateListItemUnmemoized);
