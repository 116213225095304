import { FC, useMemo } from 'react';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import {
  AlgoliaEventsCalendar,
  AlgoliaEventsCalendarProps,
} from '../../algolia/AlgoliaEventsCalendar';
import { CreateEventButton } from '../CreateEventButton';
import { SelectableEventWrapper } from './SelectableEventWrapper';
import { memo } from '../../../util/memo';
import { useUserEventsFilters } from '../../../hooks/calendar/selectable-event/useUserEventsFilters';
import {
  CALENDAR_AD_FREQUENCY,
  TOURNAMENT_CARD_BORDER_RADIUS,
} from '../../algolia/AlgoliaLayoutBidirectional';
import { TOURNAMENT_CARD_DIMENSIONS } from '../../../../functions/src/config/ads/adDimensions';
import { CALENDAR_AD } from '../../../../functions/src/util/ads/adIds';
import { useAdInjection } from '../../../hooks/ads/useAdInjection';
import { EventHit } from '../../algolia/catalog-wrappers/EventsCalendar';

export type SelectableEventsCalendarProps = Omit<
  AlgoliaEventsCalendarProps,
  | 'Wrapper'
  | 'configureOptions'
  | 'TitleRight'
  | 'initialDate'
  | 'transformedHits'
  | 'Extension'
>;

// Select an event below to view details 👇
export const EVENTS_CALENDAR_DESCRIPTION_DEFAULT =
  'Welcome to BluMint! Click an event below to register 👇';

const SelectableEventsCalendarUnmemoized: FC<SelectableEventsCalendarProps> = ({
  description = EVENTS_CALENDAR_DESCRIPTION_DEFAULT,
  height = '520px',
  ...props
}) => {
  const [calendarDateParam] = useRouterState({
    key: 'eventDate',
    silent: true,
  });

  const calendarDate = useMemo(() => {
    return calendarDateParam && !isNaN(Number(calendarDateParam))
      ? new Date(Number(calendarDateParam))
      : undefined;
  }, [calendarDateParam]);

  const filters = useUserEventsFilters();

  const configureOptions = useMemo(() => {
    return {
      filters,
    };
  }, [filters]);

  const injectAdsMain = useAdInjection<EventHit<Date>>({
    adFrequency: CALENDAR_AD_FREQUENCY,
    adWidth: TOURNAMENT_CARD_DIMENSIONS.width,
    adHeight: TOURNAMENT_CARD_DIMENSIONS.height,
    baseContainerId: CALENDAR_AD,
    adjustable: TOURNAMENT_CARD_DIMENSIONS.adjustable,
    borderRadius: TOURNAMENT_CARD_BORDER_RADIUS,
  });

  const injectAdsExtension = useAdInjection<EventHit<Date>>({
    adFrequency: CALENDAR_AD_FREQUENCY,
    adWidth: TOURNAMENT_CARD_DIMENSIONS.width,
    adHeight: TOURNAMENT_CARD_DIMENSIONS.height,
    baseContainerId: CALENDAR_AD,
    adjustable: TOURNAMENT_CARD_DIMENSIONS.adjustable,
    borderRadius: TOURNAMENT_CARD_BORDER_RADIUS,
  });

  return (
    <AlgoliaEventsCalendar
      {...props}
      Wrapper={SelectableEventWrapper}
      transformHits={injectAdsMain}
      transformHitsExtension={injectAdsExtension}
      configureOptions={configureOptions}
      description={description}
      TitleRight={<CreateEventButton sx={{ px: { xs: 2, lg: 4 } }} />}
      initialDate={calendarDate}
      height={height}
    />
  );
};

export const SelectableEventsCalendar = memo(
  SelectableEventsCalendarUnmemoized,
);
